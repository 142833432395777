import { useMemo } from "react";
import "./Footer.css";

const Footer = ({
  dimensions,
  dimensionsCode,
  dimensionsCode2,
  dimensionsCode3,
  dimensionsCode4,
  propAlignSelf,
  propColor,
  propColor1,
  propColor2,
  propColor3,
  onFrameButtonClick,
  onFrameButton1Click,
  onFrameButton2Click,
  onFrameButton3Click,
  onFrameButton4Click,
}) => {
  const bottomMenuStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const overzichtStyle = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  const dagboekStyle = useMemo(() => {
    return {
      color: propColor1,
    };
  }, [propColor1]);

  const receptenStyle = useMemo(() => {
    return {
      color: propColor2,
    };
  }, [propColor2]);

  const chatStyle = useMemo(() => {
    return {
      color: propColor3,
    };
  }, [propColor3]);

  return (
    <footer className="bottom-menu" style={bottomMenuStyle}>
      <div className="bottom-menu1">
        <button
          className="piechartoutlined-parent"
          onClick={onFrameButtonClick}
        >
          <img className="piechartoutlined-icon" alt="" src={dimensions} />
          <div className="overzicht" style={overzichtStyle}>
            Overzicht
          </div>
        </button>
        <button className="vector-parent" onClick={onFrameButton1Click}>
          <img className="vector-icon19" alt="" src={dimensionsCode} />
          <div className="dagboek" style={dagboekStyle}>
            Dagboek
          </div>
        </button>
        <button
          className="piechartoutlined-parent"
          onClick={onFrameButton2Click}
        >
          <img
            className="icon-fast-food-outline"
            alt=""
            src={dimensionsCode2}
          />
          <div className="dagboek" style={receptenStyle}>
            Recepten
          </div>
        </button>
        <button
          className="piechartoutlined-parent"
          onClick={onFrameButton3Click}
        >
          <img
            className="icon-fast-food-outline"
            alt=""
            src={dimensionsCode3}
          />
          <div className="dagboek" style={chatStyle}>
            Chat
          </div>
        </button>
        <button
          className="piechartoutlined-parent"
          onClick={onFrameButton4Click}
        >
          <img className="icon-person" alt="" src={dimensionsCode4} />
          <div className="dagboek">Profiel</div>
        </button>
      </div>
    </footer>
  );
};

export default Footer;
