import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Footer1.css";

const Footer1 = () => {
  const navigate = useNavigate();

  const onFrameButtonClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const onFrameButton1Click = useCallback(() => {
    navigate("/diary");
  }, [navigate]);

  const onFrameButton2Click = useCallback(() => {
    navigate("/recipes");
  }, [navigate]);

  const onFrameButton3Click = useCallback(() => {
    navigate("/chat");
  }, [navigate]);

  const onFrameButton4Click = useCallback(() => {
    navigate("/profiel");
  }, [navigate]);

  return (
    <div className="bottom-menu">
      <div className="bottom-menu2">
        <button className="piechartoutlined-group" onClick={onFrameButtonClick}>
          <img
            className="piechartoutlined-icon1"
            alt=""
            src="/piechartoutlined3.svg"
          />
          <div className="overzicht1">Overzicht</div>
        </button>
        <button className="vector-group" onClick={onFrameButton1Click}>
          <img className="vector-icon21" alt="" src="/vector12.svg" />
          <div className="overzicht1">Dagboek</div>
        </button>
        <button
          className="piechartoutlined-group"
          onClick={onFrameButton2Click}
        >
          <img
            className="icon-fast-food-outline1"
            alt=""
            src="/-icon-fast-food-outline3.svg"
          />
          <div className="overzicht1">Recepten</div>
        </button>
        <button
          className="piechartoutlined-group"
          onClick={onFrameButton3Click}
        >
          <img
            className="icon-fast-food-outline1"
            alt=""
            src="/-icon-chatbubble-ellipses-outline3.svg"
          />
          <div className="overzicht1">Chat</div>
        </button>
        <button
          className="piechartoutlined-group"
          onClick={onFrameButton4Click}
        >
          <img className="icon-person1" alt="" src="/-icon-person2.svg" />
          <div className="profiel2">Profiel</div>
        </button>
      </div>
    </div>
  );
};

export default Footer1;
