import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopupSave from "../components/PortalPopupSave";
import Opslaan from "../components/Opslaan";
import { format, parse } from "date-fns";
import { nl } from "date-fns/locale";
import Select from 'react-select';
import "./Editing.css";
var OOCSI = require("oocsi");



const options = [
  { value: "option-1", label: "Option 1" },
  { value: "option-2", label: "Option 2" },
  { value: "option-3", label: "Option 3" },
  // add more options here as needed
];

const Editing = () => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");
  const [isSavingState, setIsSavingState] = useState(false);

  const facts = JSON.parse(localStorage.getItem("myFacts"));
 // console.log("facts", JSON.parse(facts));

  const [counter, setCounter] = useState(0);
  const [ingredients, setIngredients] = useState([{ value: "", weight: "" }]);

  const [recipeName, setRecipeName] = useState("");

  const [listinfo, setListinfo] = useState([{ value: "", label: "" }]);
  const [joinedDinner, setJoinedDinner] = useState(false); //localStorage.getItem("dinnerJ")
const [response, setResponse] = useState(); 
const [isSaving, setIsSaving] = useState();
  const [toggleDisabled, setToggleDisabled] = useState();
  const [dayImpact, setDayImpact] = useState(0); 
  const [firstSave, setFirstSave] = useState(false); 

  //change these to the real facts
  


  const [firstIngredient, setFirstIngredient] = useState({ value: "", weight: "" });

  const incrementCounter = useCallback(() => {
    setCounter((prevCounter) => prevCounter + 1);
  }, []);

  const decrementCounter = useCallback(() => {
    setCounter((prevCounter) => prevCounter - 1);
  }, []);


  
  

  useEffect(() => {
   setIsMounted(true);
   const user = localStorage.getItem("username");
   const date = localStorage.getItem("selectedDate");
    OOCSI.connect("wss://oocsi.id.tue.nl/ws");
     setSelectedDate(date);

   const data = {
     datum: date,
     personID: user,
   };

   OOCSI.send("food_ingredient_date", data);

   OOCSI.subscribe("State_participation", function (sts) {
     setResponse(sts.data.states);

     localStorage.setItem("rowindex", parseInt(sts.data.rowindex));

     console.log(sts.data.states);

     const ingredienten = sts.data.ingredient;
     const ingCalc = JSON.parse(ingredienten);
     const allInfo = sts.data.infoin;
     const infoAllin = JSON.parse(allInfo);
     //console.log(infoAllin);

     //setFirstIngredient(ingCalc);
     const newArray = ingCalc.map((item) => ({
       value: item[0],
       label: item[0],
       weight: item[1],
     }));

     setRecipeName(sts.data.recipename);
     setIngredients(newArray);
     setDayImpact(sts.data.dayimpact);
     setCounter(parseInt(sts.data.persons)||0);
     
     const concatenated = infoAllin.map((arr) => arr.join(" "));
     const result = concatenated.map(
       (str, i) => `${infoAllin[i][0]}: climate impact ${infoAllin[i][2]} ${infoAllin[i][2]}`
     );
     //console.log(result);

     //setDayImpact(sts.data.dayimpact);
     //console.log("text first save", firstSave);

     
       if (firstSave === false) {
       setRecipeName(sts.data.recipename);
         setIngredients(newArray);
         setDayImpact(sts.data.dayimpact);
         setFirstSave(true);
          setCounter(parseInt(sts.data.persons)||0);
       } else if (firstSave === true) {
         //console.log("second");
         //setDayImpact(sts.data.dayimpact);
         // setIngredients(JSON.parse(ingredients));
       }
     
     
   });

    setTimeout(() => {
      const value = response == "Ja" ? true : false;
      // console.log("value", value);
      //
      localStorage.setItem("dinnerJ", value);
      //console.log(localStorage.getItem("dinnerJ"));
      setJoinedDinner(value);
      // console.log("This will run after 1 second!");

      //  const date = localStorage.getItem("selectedDate");

      OOCSI.connect("wss://oocsi.id.tue.nl/ws");
      // console.log("testing");

      var datatest = {
        date: "empty",
      };
      OOCSI.send("OOCSI_testing_food", datatest);

      OOCSI.subscribe("OOSCI_food_information", function (tst) {
        const allvalues = JSON.parse(JSON.stringify(tst.data.success));

        // Link all array items to a value
        const updatedList = allvalues.map((item) => ({
          value: item,
          label: item,
        }));

        setListinfo(updatedList);
      });
    }, 1000);

    setFirstSave(true);
    return () => {
      setIsMounted(false);
    };
 }, [response, isMounted]);

 var rowindex = parseInt(localStorage.getItem("rowindex"));

  
  useEffect(() => {
    setIsMounted(true);
   
    

// setTimeout(() => {
//   if (date) {
  
//     setSelectedDate(date);
//     const localStorageKey = `ingredients-${date}`;
//     // const savedIngredients = JSON.parse(
//     //   localStorage.getItem(localStorageKey)
//     // ) || [ingredients];

//     // if (Array.isArray(savedIngredients)) {
//     //   setIngredients(savedIngredients);
//     //   console.log(ingredients);
//     // }

//     // const savedRecipeName = localStorage.getItem(`recipeName-${date}`);
//     // setRecipeName(savedRecipeName || "");

//     // const savedCounter = localStorage.getItem(`counter-${date}`);
//     // setCounter(parseInt(savedCounter) || 0);
//   }
// // },1000);
// return () => {
//   setIsMounted(false);
// };
    
  }, [navigate, isMounted]);



 const handleToggleJoinedDinner = useCallback(() => {
  if (isSaving) {
    return;
  }
   if (toggleDisabled) {
     return;
   }

   // Disable the toggle for 2 seconds
   setToggleDisabled(true);
   setTimeout(() => {
     setToggleDisabled(false);
   }, 100);

   // Invert the value of `joinedDinner`
   const newJoinedDinner = !joinedDinner;

   setJoinedDinner(newJoinedDinner);
   localStorage.setItem("dinnerJ", newJoinedDinner);
   //console.log("handletoggle");
   //console.log("localstored toggle value:", localStorage.getItem("dinnerJ"));
 }, [toggleDisabled]);

 



const handleFirstIngredientChange = (selectedOption, index) => {
  setIngredients((prevIngredients) => {
    const updatedIngredients = [...prevIngredients];
    updatedIngredients[index] = {
      ...updatedIngredients[index],
      value: selectedOption.value.toString(),
      label: selectedOption.value.toString(),
      //weight: 
    };
    return updatedIngredients;
  });
};

const handleFirstIngredientWeightChange = (e, index) => {
  const updatedIngredients = [...ingredients];
  updatedIngredients[index] = {
    ...updatedIngredients[index],
    weight: e.target.value,
  };
  setIngredients(updatedIngredients);
};

 const handleRecipeNameChange = useCallback(
   (e) => {
     const value = e.target.value;
     setRecipeName(value);
   },
   [selectedDate]
 );

  const handleSave = () => {
   setFirstSave(true);
   setIsSaving(true);
   //console.log("Ingredients:", ingredients);
   //console.log("Counter:", counter);

   if (!selectedDate) {
     console.error("Cannot save ingredients without a selected date");
     return;
   }

   const localStorageKey = `ingredients-${selectedDate}`;
   localStorage.setItem(
     localStorageKey,
     JSON.stringify({ ingredients, recipeName })
   );
    //console.log(JSON.parse(JSON.stringify(ingredients)));
   const counterLocalStorageKey = `counter-${selectedDate}`;
   localStorage.setItem(counterLocalStorageKey, counter.toString());
  
   // Save the updated ingredients in local storage
    localStorage.setItem(localStorageKey, JSON.stringify(ingredients));
     //console.log(localStorage.getItem(localStorageKey));

   localStorage.setItem(`recipeName-${selectedDate}`, recipeName);

   OOCSI.connect("wss://oocsi.id.tue.nl/ws");
 
   const user = localStorage.getItem("username");
   const date = localStorage.getItem("selectedDate");
   const joiner = localStorage.getItem("dinnerJ");
   const ingredientValues = ingredients.map((ingredient) => [ingredient.value, ingredient.weight]);
    //const ingredientValues = JSON.parse(JSON.stringify(ingredients));
    localStorage.setItem(localStorageKey, JSON.stringify(ingredients));
    const selected = localStorage.getItem("selectedImage");
   
    const ingr = localStorage.getItem(localStorageKey);
    
   
    // console.log(JSON.parse(JSON.stringify(ingredients)));
 
     const data = {
       "ingredienten": JSON.stringify(ingredientValues),
       "receptnaam": recipeName,
       "personen": counter,
       "datum": date,
       "personID": user,
       "joined": joinedDinner,
       "selectedImage": JSON.stringify(selected),

       
     };

   OOCSI.send("food_ingredient_date", data);

    setTimeout(() => {
           

      setIsSaving(false);
      setIsSavingState(true);

    }, 100)
    
    setTimeout(() => {
    navigate("/detailed-day");
  }, 3000)

 };



const handleAddIngredient = useCallback(() => {
  setIngredients((prevIngredients) => [
    ...prevIngredients,
    { value: "", weight: "" },
  ]);
}, []);

const handleRemoveIngredient = useCallback((index) => {
  setIngredients((prevIngredients) => {
    const newIngredients = [...prevIngredients];
    newIngredients.splice(index, 1);
    return newIngredients;
  });
  
}, []);
  
  const onFrameButtonClick = useCallback(() => {
    const user = localStorage.getItem("username");
    const date = localStorage.getItem("selectedDate");
    const data8 = {
      datum: date,
      personID: user,
    };

    OOCSI.send("food_data_week", data8);

    setTimeout(() => {
      // console.log("timeout");
      return () => {
        setIsMounted(false);
      };
    }, 100);
    if (isMounted) {
      setTimeout(()=>{navigate("/detailed-day");}, 100)
      
    }
  }, [navigate, isMounted]);
 const closeSavePopup = useCallback(() => {
   setIsSavingState(false);
 }, []);

const placeholderText = localStorage.getItem("recipeName") || "Typ de naam van je gerecht";

  return (
    <div className="editing">
      <div className="top-bar">
        <button className="back-wrapper" onClick={onFrameButtonClick}>
          <img className="back-icon" alt="" src="/back.svg" />
        </button>
        <div className="cibus-wrapper">
          <div className="cibus">CIBUS</div>
        </div>
        <img className="vector-icon" alt="" src="/vector.svg" />
      </div>
      <div className="date">
        <div className="cibus">{selectedDate} </div>
      </div>

      <input
        className="frame-child8"
        placeholder={placeholderText}
        value={recipeName}
        onChange={handleRecipeNameChange}
      />
      <div className="joindinner-center">
        <div className="join-dinner-wrapper">
          <div className="join-dinner-text">Heb jij deze dag mee gegeten?</div>
          <button
            className={
              joinedDinner
                ? "toggle-button toggle-yes"
                : "toggle-button toggle-no"
            }
            onClick={handleToggleJoinedDinner}
          >
            {joinedDinner ? "Ja" : "Nee"}
          </button>
        </div>
      </div>

      <div className="editing-inner">
        <div className="frame-wrapper">
          <div className="aantal-personen-parent">
            <div className="aantal-personen">
              <div className="frame-parent">
                <div className="wrapper" onClick={decrementCounter}>
                  <b className="b">-</b>
                </div>
                <div className="wrapper" onClick={incrementCounter}>
                  <b className="b">+</b>
                </div>
              </div>
              <div className="parent">
                <b className="b2">{counter}</b>
                <b className="personen">personen</b>
              </div>
            </div>

            <div className="frame-group">
              {ingredients.map((ingredient, index) => (
                <div className="ingredinten-parent" key={index}>
                  <div className="select-wrapper">
                    {listinfo.length > 0 && (
                      <Select
                        options={listinfo}
                        value={ingredient}
                        onChange={(selectedOption) =>
                          handleFirstIngredientChange(selectedOption, index)
                        }
                        placeholder="Select an option"
                        className="select-input"
                        isSearchable
                      />
                    )}
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="number"
                      placeholder="0"
                      value={ingredient.weight}
                      onChange={(e) =>
                        handleFirstIngredientWeightChange(e, index)
                      }
                      className="input"
                    />
                    <p>g</p>
                  </div>
                  <button
                    className="trash"
                    onClick={() => handleRemoveIngredient(index)}
                  >
                    <img className="vector-icon20" alt="" src="/vector7.svg" />
                  </button>
                </div>
              ))}

              <button className="button-wrapper" onClick={handleAddIngredient}>
                <div className="button">
                  <b className="button1"> ingrediënt toevoegen</b>
                </div>
              </button>
              <div className="opslaan">
                <div className="button2" onClick={handleSave}>
                  <div className="button3">Opslaan</div>
                </div>
              </div>
              {isSavingState && (
                <PortalPopupSave
                  overlayColor="rgba(113, 113, 113, 0.3)"
                  placement="Centered"
                
                >
                  <Opslaan onClose={closeSavePopup} />
                </PortalPopupSave>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editing;