import { useState, useCallback, useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Option1 from "../components/Option1";
import PortalPopup from "../components/PortalPopup";
import Footer from "../components/Footer";
import "./Overview.css";
import illustration from "../Images/environment.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
var OOCSI = require("oocsi");



const Overview = () => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);

  const [isOptionPopupOpen, setOptionPopupOpen] = useState(false);
  const [weekimpact, setWeekimpact] = useState("");
  const [personalImpact, setPersonalImpact] = useState("");
  const [personalSchijf, setPersonalSchijf] = useState("");
  const [personalCal, setPersonalCal] = useState("");
  const [personalRecCal, setPersonalRecCal] = useState("");
  const [personalMeat, setPersonalMeat] = useState("");
  const [meatPercentage, setMeatPercentage] = useState("");
  //person1
  const [personalImpactP1W1, setPersonalImpactP1W1] = useState("");
  const [personalImpactP1W2, setPersonalImpactP1W2] = useState("");
  const [personalImpactP1W3, setPersonalImpactP1W3] = useState("");
  const [personalSchijfP1W1, setPersonalSchijfP1W1] = useState("");
  const [personalSchijfP1W2, setPersonalSchijfP1W2] = useState("");
  const [personalSchijfP1W3, setPersonalSchijfP1W3] = useState("");
  const [personalCalP1W1, setPersonalCalP1W1] = useState("");
  const [personalCalP1W2, setPersonalCalP1W2] = useState("");
  const [personalCalP1W3, setPersonalCalP1W3] = useState("");
  const [personalMeatP1W1, setPersonalMeatP1W1] = useState("");
  const [personalMeatP1W2, setPersonalMeatP1W2] = useState("");
  const [personalMeatP1W3, setPersonalMeatP1W3] = useState("");

  //person2
  const [personalImpactP2W1, setPersonalImpactP2W1] = useState("");
  const [personalImpactP2W2, setPersonalImpactP2W2] = useState("");
  const [personalImpactP2W3, setPersonalImpactP2W3] = useState("");
  const [personalSchijfP2W1, setPersonalSchijfP2W1] = useState("");
  const [personalSchijfP2W2, setPersonalSchijfP2W2] = useState("");
  const [personalSchijfP2W3, setPersonalSchijfP2W3] = useState("");
  const [personalCalP2W1, setPersonalCalP2W1] = useState("");
  const [personalCalP2W2, setPersonalCalP2W2] = useState("");
  const [personalCalP2W3, setPersonalCalP2W3] = useState("");
  const [personalMeatP2W1, setPersonalMeatP2W1] = useState("");
  const [personalMeatP2W2, setPersonalMeatP2W2] = useState("");
  const [personalMeatP2W3, setPersonalMeatP2W3] = useState("");

  //person3
  const [personalImpactP3W1, setPersonalImpactP3W1] = useState("");
  const [personalImpactP3W2, setPersonalImpactP3W2] = useState("");
  const [personalImpactP3W3, setPersonalImpactP3W3] = useState("");
  const [personalSchijfP3W1, setPersonalSchijfP3W1] = useState("");
  const [personalSchijfP3W2, setPersonalSchijfP3W2] = useState("");
  const [personalSchijfP3W3, setPersonalSchijfP3W3] = useState("");
  const [personalCalP3W1, setPersonalCalP3W1] = useState("");
  const [personalCalP3W2, setPersonalCalP3W2] = useState("");
  const [personalCalP3W3, setPersonalCalP3W3] = useState("");
  const [personalMeatP3W1, setPersonalMeatP3W1] = useState("");
  const [personalMeatP3W2, setPersonalMeatP3W2] = useState("");
  const [personalMeatP3W3, setPersonalMeatP3W3] = useState("");

  //person4
  const [personalImpactP4W1, setPersonalImpactP4W1] = useState("");
  const [personalImpactP4W2, setPersonalImpactP4W2] = useState("");
  const [personalImpactP4W3, setPersonalImpactP4W3] = useState("");
  const [personalSchijfP4W1, setPersonalSchijfP4W1] = useState("");
  const [personalSchijfP4W2, setPersonalSchijfP4W2] = useState("");
  const [personalSchijfP4W3, setPersonalSchijfP4W3] = useState("");
  const [personalCalP4W1, setPersonalCalP4W1] = useState("");
  const [personalCalP4W2, setPersonalCalP4W2] = useState("");
  const [personalCalP4W3, setPersonalCalP4W3] = useState("");
  const [personalMeatP4W1, setPersonalMeatP4W1] = useState("");
  const [personalMeatP4W2, setPersonalMeatP4W2] = useState("");
  const [personalMeatP4W3, setPersonalMeatP4W3] = useState("");

  let selectedImage = localStorage.getItem("selectedImage");
  let infoImage = localStorage.getItem("infoImage");
  let impactImage = localStorage.getItem("impactImage");

  let comparison = "";
  //add the facts for every day!!
  const facts = [
    "Wist je dat bonen in jouw pasta, nachoschotel of wraps, een 16x lagere klimaatimpact heeft dan gehakt?",
    "Diepvriesgroenten of conserven zijn een goede keus. Voor diepvriesgroenten en conserven worden groenten gebruikt die op de akker zijn geteelt in het vollegrondsseizoen. Dit kost minder energie dan groente uit de verwarmde kas. ~ Milieucentraal",
    "Meestal is de milieuwinst van het voorkomen van voedselverspilling veel groter dan de milieubelasting van een verpakking. ~ Milieucentraal",
    "Wist je dat 300 gram wortel 85% minder impact heeft op het klimaat dan 300 gram champignons? Kies dus af en toe voor wortel in je pasta in plaats van champignons!",
    "Van alle eiwitrijke producten zijn peulvruchten, noten, plantaardige kant-en-klaar vleesvervangers, tofu en tempé het beste voor het milieu. ~ Milieucentraal ",
    "Ook veel andere kant-en-klare vleesvervangers hebben een lage klimaatimpact. Maar vleesvervangers met veel kaas (of op basis van melk) of veel kippeneiwit hebben ongeveer evenveel impact als kip of varkensvlees! ~ Milieucentraal",
    "Sojadrank verrijkt met calcium en vitamine B12 is een volwaardige vervanger van koeienmelk, en heeft een lagere klimaatbelasting. ~ Milieucentraal",
    "Als je vlees eet en op het klimaat wilt letten, kies dan voor kip in plaats van rund (rood vlees). ~ Milieucentraal",
    "Wist je dat je met 300 gram spekjes in plaats van 500 gram gehakt in jouw eten, jouw impact 78% kunt verlagen?",
    "Onbewerkte kipfilet en 2 kippeneieren bevatten ongeveer dezelfde hoeveelheid calorieën en vallen allebei in de Schijf van 5. Maar wist je dat de klimaatimpact van eieren 2,8x lager is dan van 1 stuk kipfilet?",
    "Gemiddeld bevat de voeding van Nederlanders meer eiwit dan we nodig hebben. Af en toe geen vlees of minder vlees eten, kan dus prima. Dat is ook goed voor het klimaat. ~ Milieucentraal",
    "Wist je dat een kopje koffie 81% minder impact heeft dan een cappuccino? Toch zin in in een cappuccino? Kies dan voor plantaardige melk!",
    "Bonen in blik of in glas kopen? 300 gram bruine bonen in blik hebben maar 5% minder impact op het klimaat dan bruine bonen in glas.",
    "Vlees is verantwoordelijk voor 40 procent van broeikasgassen die vrijkomen bij productie van het voedsel van de gemiddelde Nederlander. Vlees heeft zo’n grote klimaatimpact omdat voor de productie van 1 kilo vlees gemiddeld 5 kilo plantaardig voer nodig is. ~ Milieucentraal",
    "Voorverpakte gesneden groente en fruit kunnen handig zijn, maar besef wel dat de gesneden vorm minder lang houdbaar is. Als je het snel op gaat eten en je aankoop de goede portiegrootte heeft, is voorgesneden een goede keuze. Dus als je maar een halve krop sla of kool gaat gebruiken en je de rest anders weg moet gooien, kun je beter een kleinere, voorgesneden portie kiezen. ~ Milieucentraal",
    "Wist je dat 500 gram kipfilet 29% minder impact heeft dan 500 gram hollandse garnalen?",
    "Voor het milieu is vervoer per vliegtuig beduidend slechter dan per vrachtwagen of boot. Als een product uit een ver overzees land komt, is de kans groot dat dit product per vliegtuig naar Nederland is gekomen. ~ Milieucentraal",
    "Wist je dat 2 paprikas (ongeveer 270 gram) bijna 2x zoveel klimaatimpact hebben dan 270 gram tomaten? 270 gram spinazie uit de diepvries heeft ongeveer dezelfde impact als 270 gram tomaten.",
    "Eieren en kip belasten het milieu minder dan andere dierlijke producten. Dat komt doordat kippen voor minder broeikasgassen zorgen dan schapen en koeien (ze produceren geen methaan). ~ Milieucentraal",
    "Wist je dat hummus op jouw brood de helft minder calorieën bevat en daarnaast ook jouw klimaatimpact met 26% verlaagd?",
    "Toch eens zin in een frituursnack? Een frikandel heeft 36% minder impact dan een rundvlees kroket!",
  ];
  localStorage.setItem("myFacts", JSON.stringify(facts));
  //console.log(JSON.parse(localStorage.getItem("myFacts")));

  const INTERVAL_TIME = 24 * 60 * 60 * 1000;
  //aanpassen naar startdatum

  const START_DATE = new Date("2023-06-05T00:01:00.000Z");
  const currentDate = new Date();

  const timeDifference = new Date() - new Date(START_DATE);
  const index = Math.floor(timeDifference / INTERVAL_TIME) % facts.length;

  const [currentFact, setCurrentFact] = useState(index);
  const [lastShownDate, setLastShownDate] = useState(new Date().toISOString());

  // Update currentFact and lastShownDate whenever lastShownDate changes
  useEffect(() => {
    const currentDate = new Date();
    const timeDifference = currentDate - new Date(START_DATE);
    const index = Math.floor(timeDifference / INTERVAL_TIME) % facts.length;
    setCurrentFact(index);
    setLastShownDate(currentDate.toISOString());
  }, []);

  // Store currentFact and lastShownDate in localStorage when they change
  useEffect(() => {
    localStorage.setItem("currentFact", currentFact);
  }, [currentFact]);

  useEffect(() => {
    localStorage.setItem("lastShownDate", lastShownDate);
  }, [lastShownDate]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#e5AAEE",
      },
      customColor: {
        main: "#f66047", // Custom color from the palette
      },
    },
    components: {
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: 10, // Adjust the height here (e.g., 10 for 10px)
          },
        },
      },
    },
  });

  useEffect(() => {
    const user = localStorage.getItem("username");
    const date = localStorage.getItem("selectedDate");
    const selected = localStorage.getItem("selectedImage");
    OOCSI.connect("wss://oocsi.id.tue.nl/ws");

    const data = {
      datum: date,
      personID: user,
      selectedImage: JSON.stringify(selected),
    };

    OOCSI.send("food_ingredient_date", data);
    OOCSI.send("food_data_week", data);

    OOCSI.subscribe("Data_Week1_Overview", function (dff) {
      const wkimpact1 = parseInt(dff.data.totalImpactWeek1);
      const wkimpact2 = parseInt(dff.data.totalImpactWeek2);
      const wkimpact3 = parseInt(dff.data.totalImpactWeek3);
      if (currentDate - START_DATE > 0 * 24 * 60 * 60 * 1000) {
        setWeekimpact(wkimpact1);
        console.log(wkimpact1);
      }
      if (currentDate - START_DATE > 7 * 24 * 60 * 60 * 1000) {
        setWeekimpact(wkimpact2);
         //setWeekimpact(wkimpact1);
      }
      if (currentDate - START_DATE > 14 * 24 * 60 * 60 * 1000) {
        setWeekimpact(wkimpact3);
      }

      //person1
      const week1dataP1 = dff.data.P1week1Data;
      //console.log("week1p1", week1dataP1);
      setPersonalImpactP1W1(week1dataP1[0]);
      setPersonalSchijfP1W1(week1dataP1[1].toFixed(1));
      setPersonalCalP1W1(week1dataP1[3].toFixed(1));
      setPersonalMeatP1W1(week1dataP1[2]);

      const week2dataP1 = dff.data.P1week2Data;
      setPersonalImpactP1W2(week2dataP1[0]);
      setPersonalSchijfP1W2(week2dataP1[1].toFixed(1));
      setPersonalCalP1W2(week2dataP1[3].toFixed(1));
      setPersonalMeatP1W2(week2dataP1[2]);
      const week3dataP1 = dff.data.P1week3Data;
      setPersonalImpactP1W3(week3dataP1[0]);
      setPersonalSchijfP1W3(week3dataP1[1].toFixed(1));
      setPersonalCalP1W3(week3dataP1[3].toFixed(1));
      setPersonalMeatP1W3(week3dataP1[2]);

      //person2
      const week1dataP2 = dff.data.P2week1Data;
      setPersonalImpactP2W1(week1dataP2[0]);
      setPersonalSchijfP2W1(week1dataP2[1].toFixed(1));
      setPersonalCalP2W1(week1dataP2[3].toFixed(1));
      setPersonalMeatP2W1(week1dataP2[2]);

      const week2dataP2 = dff.data.P2week2Data;
      setPersonalImpactP2W2(week2dataP2[0]);
      setPersonalSchijfP2W2(week2dataP2[1].toFixed(1));
      setPersonalCalP2W2(week2dataP2[3].toFixed(1));
      setPersonalMeatP2W2(week2dataP2[2]);
      const week3dataP2 = dff.data.P2week3Data;
      setPersonalImpactP2W3(week3dataP2[0]);
      setPersonalSchijfP2W3(week3dataP2[1].toFixed(1));
      setPersonalCalP2W3(week3dataP2[3].toFixed(1));
      setPersonalMeatP2W3(week3dataP2[2]);

      //person3
      const week1dataP3 = dff.data.P3week1Data;
      setPersonalImpactP3W1(week1dataP3[0]);
      setPersonalSchijfP3W1(week1dataP3[1].toFixed(1));
      setPersonalCalP3W1(week1dataP3[3].toFixed(1));
      setPersonalMeatP3W1(week1dataP3[2]);

      const week2dataP3 = dff.data.P3week2Data;
      setPersonalImpactP3W2(week2dataP3[0]);
      setPersonalSchijfP3W2(week2dataP3[1].toFixed(1));
      setPersonalCalP3W2(week2dataP3[3].toFixed(1));
      setPersonalMeatP3W2(week2dataP3[2]);
      const week3dataP3 = dff.data.P3week3Data;
      setPersonalImpactP3W3(week3dataP3[0]);
      setPersonalSchijfP3W3(week3dataP3[1].toFixed(1));
      setPersonalCalP3W3(week3dataP3[3].toFixed(1));
      setPersonalMeatP3W3(week3dataP3[2]);

      //person4
      const week1dataP4 = dff.data.P4week1Data;
      setPersonalImpactP4W1(week1dataP4[0]);
      setPersonalSchijfP4W1(week1dataP4[1].toFixed(1));
      setPersonalCalP4W1(week1dataP4[3].toFixed(1));
      setPersonalMeatP4W1(week1dataP4[2]);

      const week2dataP4 = dff.data.P4week2Data;
      setPersonalImpactP4W2(week2dataP4[0]);
      setPersonalSchijfP4W2(week2dataP4[1].toFixed(1));
      setPersonalCalP4W2(week2dataP4[3].toFixed(1));
      setPersonalMeatP4W2(week2dataP4[2]);

      const week3dataP4 = dff.data.P4week3Data;
      setPersonalImpactP4W3(week3dataP4[0]);
      setPersonalSchijfP4W3(week3dataP4[1].toFixed(1));
      setPersonalCalP4W3(week3dataP4[3].toFixed(1));
      setPersonalMeatP4W3(week3dataP4[2]);
    });

    setTimeout(() => {
      OOCSI.subscribe("Info_Comparison", function (drt) {
        comparison = drt.data.comparison;
        //selectedImage = comparison;
        console.log(comparison);
      });
    }, 500);

  });

  const onFrameButtonClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const onFrameButton1Click = useCallback(() => {
    navigate("/diary");
  }, [navigate]);

  const onFrameButton2Click = useCallback(() => {
    navigate("/recipes");
  }, [navigate]);

  const onFrameButton3Click = useCallback(() => {
    navigate("/chat");
  }, [navigate]);

  const onFrameButton4Click = useCallback(() => {
    navigate("/profiel");
  }, [navigate]);

  const onVectorIconClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const openOptionPopup = useCallback(() => {
    setOptionPopupOpen(true);
  }, []);

  const closeOptionPopup = useCallback(() => {
    setOptionPopupOpen(false);
  }, []);

  const [namePerson, setNamePerson] = useState("");

  useEffect(() => {
    const savedUsername = localStorage.getItem("username");

    function mapValue(value, oldMin, oldMax, newMin, newMax) {
      return (
        ((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin
      );
    }
    const oldMin = 0;
    const oldMax = 12200;
    const newMin = 0;
    const newMax = 100;
    let value;

    //let schijfvan5perc = "";
    if (savedUsername === "Deelnemer1") {
      setNamePerson("Rob");
      const calories = 2900;
      setPersonalRecCal((0.341 * calories).toFixed(0).replace(".", ","));
      console.log(namePerson);
      if (currentDate - START_DATE > 0 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 0 days since the start date.");
        setPersonalImpact(personalImpactP1W1);
        setPersonalSchijf(personalSchijfP1W1);
        setPersonalCal(personalCalP1W1);

        setPersonalMeat((personalMeatP1W1 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP1W1 <= 12200) {
          value = personalMeatP1W1;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 7 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 7 days since the start date.");
        setPersonalImpact(personalImpactP1W2);
        setPersonalSchijf(personalSchijfP1W2);
        setPersonalCal(personalCalP1W2);

        setPersonalMeat((personalMeatP1W2 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP1W2 <= 12200) {
          value = personalMeatP1W2;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 14 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 14 days since the start date.");
        setPersonalImpact(personalImpactP1W3);
        setPersonalSchijf(personalSchijfP1W3);
        setPersonalCal(personalCalP1W3);

        setPersonalMeat((personalMeatP1W3 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP1W3 <= 12200) {
          value = personalMeatP1W3;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
    } else if (savedUsername === "Deelnemer2") {
      setNamePerson("Daan");
      //setting calories
      const calories = 3600;
      setPersonalRecCal((0.341 * calories).toFixed(0).replace(".", ","));
      console.log(namePerson);
      if (currentDate - START_DATE > 0 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 0 days since the start date.");
        setPersonalImpact(personalImpactP2W1);
        setPersonalSchijf(personalSchijfP2W1);
        setPersonalCal(personalCalP2W1);

        setPersonalMeat((personalMeatP2W1 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP2W1 <= 12200) {
          value = personalMeatP2W1;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 7 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 7 days since the start date.");
        setPersonalImpact(personalImpactP2W2);
        setPersonalSchijf(personalSchijfP2W2);
        setPersonalCal(personalCalP2W2);

        setPersonalMeat((personalMeatP2W2 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP2W2 <= 12200) {
          value = personalMeatP2W2;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log("Daan", mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 14 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 14 days since the start date.");
        setPersonalImpact(personalImpactP2W3);
        setPersonalSchijf(personalSchijfP2W3);
        setPersonalCal(personalCalP2W3);

        setPersonalMeat((personalMeatP2W3 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP2W3 <= 12200) {
          value = personalMeatP2W3;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
    } else if (savedUsername === "Deelnemer3") {
      setNamePerson("Astrid");
      const calories = 2400;
      setPersonalRecCal((0.341 * calories).toFixed(0).replace(".", ","));
      console.log(namePerson);
      if (currentDate - START_DATE > 0 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 0 days since the start date.");
        setPersonalImpact(personalImpactP3W1);
        setPersonalSchijf(personalSchijfP3W1);
        setPersonalCal(personalCalP3W1);

        setPersonalMeat((personalMeatP3W1 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP3W1 <= 12200) {
          value = personalMeatP3W1;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 7 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 7 days since the start date.");
        setPersonalImpact(personalImpactP3W2);
        setPersonalSchijf(personalSchijfP3W2);
        setPersonalCal(personalCalP3W2);

        setPersonalMeat((personalMeatP3W2 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP3W2 <= 12200) {
          value = personalMeatP3W2;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 14 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 14 days since the start date.");
        setPersonalImpact(personalImpactP3W3);
        setPersonalSchijf(personalSchijfP3W3);
        setPersonalCal(personalCalP3W3);

        setPersonalMeat((personalMeatP3W3 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP3W3 <= 12200) {
          value = personalMeatP3W3;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
    } else if (savedUsername === "Britt") {
      setNamePerson("Britt");
      const calories = 2300;
      setPersonalRecCal((0.341 * calories).toFixed(0).replace(".", ","));
      console.log(namePerson);
      if (currentDate - START_DATE > 0 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 0 days since the start date.");
        setPersonalImpact(personalImpactP3W1);
        setPersonalSchijf(personalSchijfP3W1);
        setPersonalCal(personalCalP3W1);

        setPersonalMeat((personalMeatP3W1 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP3W1 <= 12200) {
          value = personalMeatP3W1;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 7 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 7 days since the start date.");
        setPersonalImpact(personalImpactP3W2);
        setPersonalSchijf(personalSchijfP3W2);
        setPersonalCal(personalCalP3W2);

        setPersonalMeat((personalMeatP3W2 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP3W2 <= 12200) {
          value = personalMeatP3W2;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 14 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 14 days since the start date.");
        setPersonalImpact(personalImpactP3W3);
        setPersonalSchijf(personalSchijfP3W3);
        setPersonalCal(personalCalP3W3);

        setPersonalMeat((personalMeatP3W3 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP3W3 <= 12200) {
          value = personalMeatP3W3;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
    } else if (savedUsername === "Deelnemer4") {
      setNamePerson("Teun");
      const calories = 3600;
      setPersonalRecCal((0.341 * calories).toFixed(0).replace(".", ","));
      console.log(namePerson);
      if (currentDate - START_DATE > 0 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 0 days since the start date.");
        setPersonalImpact(personalImpactP4W1);
        setPersonalSchijf(personalSchijfP4W1);
        setPersonalCal(personalCalP4W1);

        setPersonalMeat((personalMeatP4W1 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP4W1 <= 12200) {
          value = personalMeatP4W1;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 7 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 7 days since the start date.");
        setPersonalImpact(personalImpactP4W2);
        setPersonalSchijf(personalSchijfP4W2);
        setPersonalCal(personalCalP4W2);

        setPersonalMeat((personalMeatP4W2 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP4W2 <= 12200) {
          value = personalMeatP4W2;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
      if (currentDate - START_DATE > 14 * 24 * 60 * 60 * 1000) {
        console.log("It has been more than 14 days since the start date.");
        setPersonalImpact(personalImpactP4W3);
        setPersonalSchijf(personalSchijfP4W3);
        setPersonalCal(personalCalP4W3);

        setPersonalMeat((personalMeatP4W3 / 1000).toFixed(1).replace(".", ","));

        if (personalMeatP4W3 <= 12200) {
          value = personalMeatP4W3;
        } else {
          value = 12200;
        }
        const mappedValue = mapValue(value, oldMin, oldMax, newMin, newMax);
        console.log(mappedValue);
        setMeatPercentage(mappedValue);
      }
    }
  });

  let impactComparison = impactImage * (personalImpact / 1000);
  return (
    <>
      <div className="overview">
        <div className="top-bar1">
          <img
            className="vector-icon5"
            alt=""
            src="/vector4.svg"
            onClick={onVectorIconClick}
          />
          <div className="cibus-container">
            <div className="div">CIBUS</div>
          </div>
          <img className="vector-icon6" alt="" src="/vector.svg" />
        </div>
        <div className="background-overview">
          <div className="headeroverview">
            <h2 className="naam">{namePerson}</h2>
            <h3 className="het-persoonlijke-weekoverzicht">
              Jouw persoonlijke weekoverzicht
            </h3>
          </div>
          <img src={illustration} className="image-illustration" />
          <div className="allcomponents">
            <section className="weekdoel">
              <div className="headertext">
                <h1 className="weekdoeltext">
                  <b className="weekdoel-klimaatimpact-vlees">Weekdoel vlees</b>
                </h1>
                <div className="averagewallet">
                  <div className="weekdoel-klimaatimpact-vlees">
                    12,2 kg CO2 pp
                  </div>
                </div>
              </div>
              <div className="frame-parent4">
                <div className="frame-parent5">
                  <div className="mijn-klimaatimpact-vlees-wrapper">
                    <div className="weekdoel-klimaatimpact-vlees">
                      Mijn impact: {personalMeat} kg CO <sub>2</sub>
                    </div>
                  </div>
                  <ThemeProvider theme={theme}>
                    <Box className="frame" sx={{ width: "100%" }}>
                      <LinearProgress
                        color={
                          meatPercentage >= 100 ? "customColor" : "primary"
                        }
                        variant="determinate"
                        value={meatPercentage}
                      />
                    </Box>
                  </ThemeProvider>
                </div>
              </div>
            </section>

            <div className="fourbuttons">
              <div className="top2sections">
                <section className="climateimpact">
                  <div className="climatetext">
                    <h3 className="headerclimate">
                      <p
                        className="jouw-impact-deze"
                        id="StandardText"
                        text="Jouw impact deze week"
                      >
                        Gezamenlijke impact
                      </p>
                    </h3>
                    <div className="countingimpact">
                      <h1 className="amount">
                        <div className="div">
                          {(weekimpact / 1000).toFixed(0)}{" "}
                        </div>
                      </h1>
                      <p className="gram-co2">
                        kg CO<sub>2</sub>{" "}
                      </p>
                    </div>
                    <div className="comparablehouseholds">
                      <p
                        className="vergelijkbare-huishoudens"
                        id="StandardText"
                        text="Vergelijkbare huishoudens:"
                      >
                        Vergelijkbare huishoudens:
                      </p>
                      <p
                        className="gram-co2"
                        id="2705 gram CO2"
                        WaardeHouseholds="2705 gram CO2"
                      >
                        {((weekimpact * 1.1) / 1000).toFixed(0)} kg CO
                        <sub>2</sub>
                      </p>
                    </div>
                  </div>
                </section>
                <section className="comparisonsection">
                  <div className="contentcomparisonframe">
                    <div className="impactamount">
                      <div className="group">
                        <p className="p1" id="StandardText" CO2Value="9202"></p>
                        <p
                          className="staat-gelijk-aan"
                          id="StandardText"
                          CO2="CO2"
                        ></p>
                      </div>
                      <p
                        className="staat-gelijk-aan"
                        id="StandardText"
                        GelijkAan="staat gelijk aan"
                      >
                        Jouw impact van{" "}
                        {(personalImpact / 1000).toFixed(1).replace(".", ",")}{" "}
                        kg CO
                        <sub>2</sub> staat gelijk aan:
                      </p>
                    </div>
                    <div className="iconcomparison">
                      <img
                        className="car-icon1"
                        alt=""
                        src={selectedImage ? selectedImage : comparison}
                      />
                    </div>
                    <div className="comparisoninfo">
                      <div className="amount1">
                        <p className="km" id="ComparisonValue" Car="251" Beer>
                          {impactComparison.toFixed(0)} {infoImage}
                        </p>
                        <p
                          className="km"
                          id="StandardText"
                          ComparisonAmount="km"
                        ></p>
                      </div>
                      <p
                        className="met-de-auto"
                        id="StandardText"
                        CarText="met de auto"
                      ></p>
                    </div>
                  </div>
                  <button className="editcomparison" onClick={openOptionPopup}>
                    <img className="edit-icon" alt="" src="/edit.svg" />
                  </button>
                </section>
              </div>
              <div className="bottom2sections">
                <section className="calories">
                  <div className="calorieinfo">
                    <div className="aanbevolen-kcal-avondeten-parent">
                      <p
                        className="gemiddeld-kcal-deze"
                        id="StandardText"
                        Calories="Gemiddeld kCal deze week"
                      >
                        Gemiddeld aantal calorieën
                      </p>
                      <p className="p4" id="CaloriesValue" Taken="900">
                        {personalCal}
                      </p>
                    </div>
                    <div className="aanbevolen-kcal-avondeten-parent">
                      <p
                        className="aanbevolen-kcal-avondeten"
                        id="StandardText"
                        Calories="Aanbevolen kCal avondeten"
                      >
                        Aanbevolen aantal calorieën
                      </p>
                      <p className="p3" id="CaloriesValue" Suggested="800">
                        {personalRecCal}
                      </p>
                    </div>
                  </div>
                </section>

                <section className="percentagefive">
                  <div className="amount2">
                    <h1 className="h1">{personalSchijf}</h1>
                
                    <p className="ingredint">%</p>
                  </div>
                  <p className="infotext3">
                    <p
                      className="van-jouw-avondeten"
                      id="StandardText"
                      uitlegSchijf="van jouw avondeten uit de schijf van 5 deze week"
                    >
                      uit de schijf van 5
                    </p>
                  </p>
                </section>
              </div>
            </div>
            <section className="weekfact">
              <p className="hier-komt-een-container">
                <span className="hier-komt-een">{facts[currentFact]}</span>
              </p>
            </section>
          </div>
        </div>
        <Footer
          dimensions="/piechartoutlined.svg"
          dimensionsCode="/vector11.svg"
          dimensionsCode2="/-icon-fast-food-outline.svg"
          dimensionsCode3="/-icon-chatbubble-ellipses-outline.svg"
          dimensionsCode4="/-icon-person.svg"
          propAlignSelf="unset"
          propColor="#f66047"
          propColor1="#9f9f9f"
          propColor2="#9f9f9f"
          propColor3="#9f9f9f"
          onFrameButtonClick={onFrameButtonClick}
          onFrameButton1Click={onFrameButton1Click}
          onFrameButton2Click={onFrameButton2Click}
          onFrameButton3Click={onFrameButton3Click}
          onFrameButton4Click={onFrameButton4Click}
        />
      </div>
      {isOptionPopupOpen && (
        <PortalPopup
          overlayColor="rgba(255, 255, 255, 0.5)"
          placement="Centered"
          onOutsideClick={closeOptionPopup}
        >
          <Option1 onClose={closeOptionPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default Overview;
