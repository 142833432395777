import "./FrameComponent.css";

const FrameComponent = () => {
  return (
    <div className="main-frame-parent">
      <img className="main-frame-icon" alt="" src="/main-frame.svg" />
    </div>
  );
};

export default FrameComponent;
