import "./ReceiptCard.css";

const ReceiptCard = () => {
  return (
    <div className="naam-recept2">
      <input className="naamrecept" type="text" placeholder="Naam recept" />
      <div className="minuten1">
        <section className="buttonplusmin1">
          <button className="min">
            <b className="b6">-</b>
          </button>
          <button className="min">
            <b className="b6">+</b>
          </button>
        </section>
        <div className="minuten2">
          <b className="b8">00</b>
          <b className="minuten3">minuten</b>
        </div>
      </div>
    </div>
  );
};

export default ReceiptCard;
