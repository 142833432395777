import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Footer1 from "../components/Footer1";
import "./Profiel.css";
import profiel from "../Images/profile.png";

const Profiel = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const onVectorIconClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const onLogoutClick = useCallback(() => {
    // Perform logout actions, such as clearing user session, etc.
    navigate("/");
  }, [navigate]);

  const savedUsername = localStorage.getItem('username');


  return (
    <div className="profiel">
      <div className="top-bar8">
        <button className="home2" onClick={onHomeClick}>
          <img
            className="vector-icon15"
            alt=""
            src="/vector8.svg"
            onClick={onVectorIconClick}
          />
        </button>
        <div className="name4">
          <div className="cibus8">CIBUS</div>
        </div>
        <img className="vector-icon16" alt="" src="/vector.svg" />
      </div>
      <div className="mainframe1">
        <div className="image1">
          <img className="image-item" alt="" src={profiel} />
        </div>
        <div className="profielinfo">
          <div className="mijnprofiel">
            <div className="framing">
              <div className="group1">
                <h2 className="mijn-profiel">Mijn profiel</h2>
                <div className="explanationgebruikww">
                  <h3 className="gebruikersnaam">Gebruikersnaam:</h3>
                  <h3 className="gebruikersnaam">Wachtwoord:</h3>
                </div>
                <div className="gebruikww">
                  <p className="gebruikersnaam1">{savedUsername}</p>
                  <p className="gebruikersnaam1">***********</p>
                </div>
              </div>
            </div>
          </div>
          <button className="filter">
            <div className="filter-on-climate" onClick={onLogoutClick}>
              Uitloggen
            </div>
          </button>

          <div className="sources">
            <h2 className="bronvermelding-en-data">
              Bronvermelding en Data Disclaimer
            </h2>
            <p className="informatie-text">
              Allereerst wil ik benoemen dat ik in de app altijd de impact benoem als (kilo)gram CO
              <sub>2</sub>.
              {""} Dit is echter de CO<sub>2</sub>-equivalent (de
              uitstoot van de broeikasgassen omgerekend naar CO<sub>2</sub>.)
            </p>
            <p className="informatie-text">
              <b>Chatbot informatie</b> <br></br>
              Ik gebruik de chatbotfunctie van OpenAI, een externe partij, via
              Data Foundry van de universiteit. De universiteit houdt bij welke
              vragen er gesteld worden om de kwaliteit te controleren en
              eventuele problemen op te lossen. OpenAI slaat alle gegevens op in
              de VS en bewaart de gegevens die via de API worden verzonden
              maximaal 30 dagen voor monitoring. Ik kan zien welke vragen
              gesteld worden, maar deze worden niet aan de persoon gelinkt die
              de vraag stelt. Het is dus belangrijk om bewust te zijn van de
              vragen die je stelt en geen persoonlijke informatie te delen maar
              deze chatbot echt te gebruiken om kennis te krijgen over de
              klimaatimpact van voeding.
            </p>
            <p className="informatie-text">
              <b>Bronnen</b> <br></br>
              Om de klimaatimpact en de hoeveelheid calorieën te berekenen van
              voedingsmiddelen, wordt er gebruik gemaakt van data van het RIVM.
              <p>
                <b>Voor de klimaatimpact: </b>
              </p>
              <p>
                Rijksinstituut voor Volksgezondheid en Mileu (RIVM) (2021).
                Database Milieubelasting voedingsmiddelen - Databaseversie 4
                oktober 2019.{" "}
              </p>
              Meer informatie over de data kunt u vinden door op{" "}
              <a
                href="https://www.rivm.nl/voedsel-en-voeding/duurzaam-voedsel/database-milieubelasting-voedingsmiddelen"
                target="_blank"
              >
                deze link
              </a>{" "}
              te klikken.
              <p>
                <b>Voor de gezondheidsinformatie: </b>
              </p>
              <p>NEVO-online versie 2021/7.1, RIVM, Bilthoven</p>
              Meer informatie over de data kunt u vinden door op{" "}
              <a
                href="https://www.rivm.nl/nederlands-voedingsstoffenbestand/toelichting-nevo-gegevens/voedingsmiddelen"
                target="_blank"
              >
                deze link
              </a>{" "}
              te klikken.
            </p>

            <p className="informatie-text">
              <b>Bronnen feitjes Milieucentraal</b> <br></br>
              <p className="informatie-text">
                Algemeen:
                <a
                  href="https://www.milieucentraal.nl/eten-en-drinken/milieubewust-eten/groente-en-fruit/#impact-van-teelt-verpakkingen-en-vervoer"
                  target="_blank"
                >
                  https://www.milieucentraal.nl/eten-en-drinken/milieubewust-eten/groente-en-fruit/#impact-van-teelt-verpakkingen-en-vervoer
                </a>
              </p>
              <p className="informatie-text">
                Vleesvervangers:
                <a
                  href="https://www.milieucentraal.nl/eten-en-drinken/milieubewust-eten/vleesvervangers/"
                  target="_blank"
                >
                  {" "}
                  https://www.milieucentraal.nl/eten-en-drinken/milieubewust-eten/vleesvervangers/
                </a>
              </p>
              <p className="informatie-text">
                Zuivel:
                <a
                  href="https://www.milieucentraal.nl/eten-en-drinken/milieubewust-eten/zuivel/"
                  target="_blank"
                >
                  https://www.milieucentraal.nl/eten-en-drinken/milieubewust-eten/zuivel/
                </a>
              </p>
              <p className="informatie-text">
                Vlees:
                <a
                  href=" https://www.milieucentraal.nl/eten-en-drinken/milieubewust-eten/vlees/"
                  target="_blank"
                >
                  https://www.milieucentraal.nl/eten-en-drinken/milieubewust-eten/vlees/
                </a>
              </p>
            </p>
            <p className="informatie-text">
              <b>Bronnen recepten</b> <br></br>
              De afbeelding voor de Spaghetti is van{" "}
              <a href="https://pixabay.com/nl/users/monika1607-2963260/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4546233">
                ❤ Monika 💚 💚 Schröder ❤
              </a>{" "}
              via{" "}
              <a href="https://pixabay.com/nl//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4546233">
                Pixabay
              </a>
              De afbeeldingen van de andere recepten zijn gegenereerd met behulp
              van kunstmatige intelligentie. Afbeeldingen van recepten kunnen
              dus afwijken van de werkelijkheid. De afbeeldingen zijn
              gegenereerd door{" "}
              <a
                href="https://huggingface.co/spaces/stabilityai/stable-diffusion"
                target="_blank"
              >
                Stable Diffusion.
              </a>
              <p>
                De receptinforamtie is gegenereerd met behulp van{" "}
                <a href="https://openai.com/blog/chatgpt" target="_blank">
                  OpenAI (chatGPT)
                </a>{" "}
                (versie March 23 en May 3) en aangepast en beoordeeld door
                mijzelf als onderzoeker.{" "}
              </p>
              <p>
                {" "}
                Aan de hand van de{" "}
                <a href="https://https://www.vers-bestellen.nl/c-2083409/groente-fruit-kalender/">
                  Groente & Fruitkalender van De Gezonde Apotheker
                </a>{" "}
                {""} is bepaald of recepten in het seizoen zijn (wanneer alleen
                de ui in het seizoen is, wordt er niet aangegeven met het
                icoontje dat het seizoensgroenten bevat).
                {""}{" "}
              </p>
              <p>
                Ten slotte is de klimaatimpact van de recepten berekend met
                behulp van de Database Milieubelasting voedingsmiddelen. Sommige
                voedselproducten zijn echter niet aanwezig in deze database. In
                dit geval is er daarom ook gekeken naar een product die in de
                buurt komt van het desbetreffende product om zo toch een
                indicatie te geven van de klimaatimpact.
              </p>
              <p>
                Daarnaast wil ik ook benoemen dat het seizoen of de afkomst van
                de producten niet is meegenomen in deze berekeningen. Bij vragen
                kan je altijd contact opnemen met mij als onderzoeker: Britt
                Stegers +31621938925 .
              </p>
            </p>
            <p className="informatie-text">
              <b>Weekbudget vlees</b> <br></br>
              De Volkskrant laat in{" "}
              <a href="https://www.volkskrant.nl/nieuws-achtergrond/nederlanders-willen-best-hun-persoonlijke-co2-handelsysteem-zo-lang-de-lasten-eerlijk-worden-verdeeld~b44f6922/">
                een artikel
              </a>
              zien op basis van een onderzoek van{" "}
              <a href="https://www.tno.nl/nl/">TNO</a> dat een huishouden van
              2.2 personen een jaarbudget voor vlees zou hebben van 1.4 ton CO
              <sub>2</sub>-equivalent (alle uitstoot omgerekend naar CO
              <sub>2</sub>). Dat is omgerekend 12.2 kg CO<sub>2</sub>-equivalent
              per week. Hierop is het weekbudget vlees gebaseerd.
            </p>
          </div>
        </div>
      </div>
      <Footer1 />
    </div>
  );
};

export default Profiel;
