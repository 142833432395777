import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import "./Diary.css";
var OOCSI = require("oocsi");

const Diary = () => {
  const navigate = useNavigate();

  const [dates, setDates] = useState([]);

  const [isMounted, setIsMounted] = useState(false);
   useEffect(() => {
     setIsMounted(true);
     //try {
     // Connect to OOCSI
     OOCSI.connect("wss://oocsi.id.tue.nl/ws");
     //console.log("test");
     const user = localStorage.getItem("username");
     const date = localStorage.getItem("selectedDate");
     const selected = localStorage.getItem("selectedImage");

     var data = {
       personID: user,
       datum: date,
       selectedImage: JSON.stringify(selected),
     };
     OOCSI.send("food_ingredient_date", data);

     OOCSI.subscribe("Sending_Data", function (drt) {
       const response = drt.data.success;

       setDates(response);
       //console.log(response);

       // });
       //  } catch (error) {
       //  console.error(error);
       //  }

       return () => {
         setIsMounted(false);
       };
     });
   }, [isMounted]);



  const onFrameButtonClick = useCallback(() => {
    if (isMounted) {
      navigate("/Overview");
    }
  }, [navigate, isMounted]);

  const onFrameButton1Click = useCallback(() => {
    if (isMounted) {
      navigate("/diary");
    }
  }, [navigate, isMounted]);

  const onFrameButton2Click = useCallback(() => {
    if (isMounted) {
      navigate("/recipes");
    }
  }, [navigate, isMounted]);

  const onFrameButton3Click = useCallback(() => {
    if (isMounted) {
      navigate("/chat");
    }
  }, [navigate, isMounted]);

  const onFrameButton4Click = useCallback(() => {
    if (isMounted) {
      navigate("/profiel");
    }
  }, [navigate, isMounted]);

  const onHomeClick = useCallback(() => {
    if (isMounted) {
      navigate("/Overview");
    }
  }, [navigate, isMounted]);

  const onVectorIconClick = useCallback(() => {
    if (isMounted) {
      navigate("/Overview");
    }
  }, [navigate, isMounted]);

  const onAddClick = useCallback(() => {
    if (isMounted) {
      navigate("/adding-data");
    }
  }, [navigate, isMounted]);

  const onDateClick = useCallback(
  
    (date) => {
      setIsMounted(true);
      localStorage.setItem("selectedDate", date);
      OOCSI.connect("wss://oocsi.id.tue.nl/ws");
      console.log(date);
      var datas = {
        date: "datum",
      };
      OOCSI.send("food_update_OOCSI", datas);
      setTimeout(() => {
         return () => {
           setIsMounted(false);
         };
          
      }, 50)
      setTimeout(() => {
        if (isMounted) {
          navigate("/detailed-day");
        }
      }, 50);
    
    },
    [navigate, isMounted]
  );

 


  return (
    <div className="diary">
      <div className="top-bar3">
        <button className="home" onClick={onHomeClick}>
          <img
            className="vector-icon8"
            alt=""
            src="/vector8.svg"
            onClick={onVectorIconClick}
          />
        </button>
        <div className="name">
          <div className="cibus3">CIBUS</div>
        </div>

        <img alt="" />
      </div>
      <div className="mainframe">
        <div className="header">
          <b className="jouw-historieoverzicht">Categorieën</b>
          <div className="categoryinfo">
            <div className="seasons-wrapper">
              <div className="category">
                <div className="circles red"></div>
                <div className="infotext">Vlees, vis, gevogelte</div>
              </div>
              <div className="category">
                <div className="circles green"></div>
                <div className="infotext">Groente en fruit</div>
              </div>
              <div className="category">
                <div className="circles blue"></div>
                <div className="infotext">Granen en aardappel</div>
              </div>
              <div className="category">
                <div className="circles orange"></div>
                <div className="infotext">Zuivel</div>
              </div>
              <div className="category">
                <div className="circles purple"></div>
                <div className="infotext">Overig</div>
              </div>
            </div>
          </div>

          <b className="jouw-historieoverzicht">Jouw historieoverzicht</b>
        </div>
        {dates.map((date) => (
          <button className="dates" onClick={() => onDateClick(date)}>
            <div className="datum1">
              <div className="contentdatum" id="Date" dateday={date}>
                <div className="maart-20232">{date}</div>
              </div>
            </div>
          </button>
        ))}
      </div>
      <Footer
        dimensions="/piechartoutlined2.svg"
        dimensionsCode="/vector10.svg"
        dimensionsCode2="/-icon-fast-food-outline.svg"
        dimensionsCode3="/-icon-chatbubble-ellipses-outline.svg"
        dimensionsCode4="/-icon-person.svg"
        propAlignSelf="unset"
        propColor="#9f9f9f"
        propColor1="#f66047"
        propColor2="#9f9f9f"
        propColor3="#9f9f9f"
        onFrameButtonClick={onFrameButtonClick}
        onFrameButton1Click={onFrameButton1Click}
        onFrameButton2Click={onFrameButton2Click}
        onFrameButton3Click={onFrameButton3Click}
        onFrameButton4Click={onFrameButton4Click}
      />
    </div>
  );
};

export default Diary;
