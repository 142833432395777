import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Privacy.css";

const Privacy = ({ onClose }) => {
  const navigate = useNavigate();

  const onKruisButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="privacy">
      <div className="kruisje1">
        <button className="kruisbutton1" onClick={onKruisButtonClick}>
          <img className="vector-icon3" alt="" src="/vector3.svg" />
        </button>
      </div>
      <div className="privacy1">Privacy</div>
      <div className="om-deel-te">
        Om deel te nemen aan het onderzoek zal je ook een toestemmingsformulier
        moeten ondertekenen. In een kopie van het toestemmingsformilier kun je
        nogmaals lezen over de privacy.
      </div>
    </div>
  );
};

export default Privacy;
