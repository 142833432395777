import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ReceiptCard from "../components/ReceiptCard";
import IngredientContainer from "../components/IngredientContainer";
import "./AddingRecipe.css";

const AddingRecipe = () => {
  const navigate = useNavigate();

  const onBackClick = useCallback(() => {
    navigate("/recipes");
  }, [navigate]);

  return (
    <div className="adding-recipe">
      <div className="top-bar6">
        <button className="back1" onClick={onBackClick}>
          <img className="back-icon4" alt="" src="/back2.svg" />
        </button>
        <div className="name2">
          <div className="cibus6">CIBUS</div>
        </div>
        <img className="vector-icon12" alt="" src="/vector.svg" />
      </div>
      <div className="image">
        <img className="image-child" alt="" src="/rectangle-2@2x.png" />
      </div>
      <div className="main">
        <div className="main1">
          <div className="impact">
            <div className="impacttext">
              <p className="p6" id="CO2" EstimatedCO2>
                000
              </p>
              <p className="g-co21" id="StandardText" annotation="g CO2">
                g CO2
              </p>
            </div>
          </div>
          <ReceiptCard />
          <IngredientContainer productId="/info.svg" />
          <div className="header-bereidingswijze-parent">
            <p
              className="header-bereidingswijze1"
              id="StandardText"
              Bereidingswijze
            >
              <b className="bereidingswijze1">Bereidingswijze</b>
            </p>
            <textarea
              className="bereidingswijze2"
              placeholder="Typ hier welke stappen je moet nemen om het gerecht te maken"
            />
          </div>
          <button className="opslaan2">
            <div className="button8">
              <div className="button9">Opslaan</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddingRecipe;
