import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Option1.css";
var OOCSI = require("oocsi");


const Option1 = ({ onClose }) => {
  const navigate = useNavigate();
   const [isMounted, setIsMounted] = useState(false);

   `
   `

  
  let savedIMG = localStorage.getItem("selectedImage");
  let name;
  if (savedIMG === "/beer_good.svg") {
    name = 0;
  } else if (savedIMG === "/laundry_good.svg") {
    name = 1;
  } else if (savedIMG === "/car_good.svg") {
    name = 2;
  } else {
    name = 3;
  }
  console.log(savedIMG);
  const [activeIndex, setActiveIndex] = useState(name);

  const handleButtonClick = (buttonIndex, imageName) => {
    setActiveIndex(buttonIndex);
    const NameImage = buttonImages[buttonIndex].inactive;
    localStorage.setItem("selectedImage", NameImage);
    const infoImage = buttonImages[buttonIndex].information;
    localStorage.setItem("infoImage", infoImage);
    const impactImage = buttonImages[buttonIndex].impact;
    localStorage.setItem("impactImage", impactImage);
    //console.log(NameImage);
    console.log(impactImage);
    
  };
  const onSaveButtonClick = useCallback(() => {
    setIsMounted(true);
     onClose();
     const selected = localStorage.getItem("selectedImage");
     const user = localStorage.getItem("username");
     const date = localStorage.getItem("selectedDate");
     const data8 = {
       datum: date,
       personID: user,
       selectedImage: JSON.stringify(selected),
     };

    OOCSI.send("food_data_week", data8);
    
    setTimeout(() => {
      return () => {
        setIsMounted(false);
      };
    },500)
   }, [onClose, isMounted]); 

  const buttonImages = [
    {
      active: "/beer_off.svg",
      inactive: "/beer_good.svg",
      information: "flesjes bier",
      impact: 1.11,
    },
    {
      active: "/laundry_off.svg",
      inactive: "/laundry_good.svg",
      information: "wasjes in de wasmachine",
      impact: 1.33,
    },
    {
      active: "/car_off.svg",
      inactive: "/car_good.svg",
      information: "kilometer met de auto ",
      impact: 1.89,
    },
    {
      active: "/shower_off.svg",
      inactive: "/shower_good.svg",
      information: "minuten douchen",
      impact: 10.91,
    },
  ];
  const onKruisButtonClick = useCallback(() => {
    //if (isMounted) {
      onClose();
    
  }, [onClose]);

  return (
    <div className="option">
      <div className="kruisje">
        <button className="kruisbutton" onClick={onKruisButtonClick}>
          <img className="vector-icon1" alt="" src="/vector1.svg" />
        </button>
      </div>
      <div className="kies-jour-vergelijking-wrapper">
        <div className="kies-jour-vergelijking">Kies jouw vergelijking</div>
      </div>
      <div className="options">
        <div className="firstlinecomaprison">
          <button
            className={`toiletpaper ${
              activeIndex === 0 ? "active" : "inactive"
            }`}
            onClick={() => handleButtonClick(0)}
            key={0}
          >
            <div className="toilet-paper">
              <img
                className="vector-icon2"
                alt=""
                src={
                  0 === activeIndex
                    ? buttonImages[0].inactive
                    : buttonImages[0].active
                }
              />
            </div>
          </button>
          <button
            className={`toiletpaper ${
              activeIndex === 1 ? "active" : "inactive"
            }`}
            onClick={() => handleButtonClick(1)}
            key={1}
          >
            <div className="toilet-paper">
              <img
                className="vector-icon2"
                alt=""
                src={
                  1 === activeIndex
                    ? buttonImages[1].inactive
                    : buttonImages[1].active
                }
              />
            </div>
          </button>
        </div>
        <div className="secondlinecomparison">
          <button
            className={`toiletpaper ${
              activeIndex === 2 ? "active" : "inactive"
            }`}
            onClick={() => handleButtonClick(2)}
            key={2}
          >
            <div className="toilet-paper">
              <img
                className="vector-icon2"
                alt=""
                src={
                  2 === activeIndex
                    ? buttonImages[2].inactive
                    : buttonImages[2].active
                }
              />
            </div>
          </button>
          <button
            className={`toiletpaper ${
              activeIndex === 3 ? "active" : "inactive"
            }`}
            onClick={() => handleButtonClick(3)}
            key={3}
          >
            <div className="toilet-paper">
              <img
                className="vector-icon2"
                alt=""
                src={
                  3 === activeIndex
                    ? buttonImages[3].inactive
                    : buttonImages[3].active
                }
              />
            </div>
          </button>
        </div>
      </div>
      <button className="opslaan1" onClick={onSaveButtonClick}>
        <div className="button4" onClick={onSaveButtonClick}>
          <div className="button5">Opslaan</div>
        </div>
      </button>
    </div>
  );
};

export default Option1;
