import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import RecipeContainer from "../components/RecipeContainer";
import Footer from "../components/Footer";
import "./Recipes.css";

const Recipes = () => {
  const navigate = useNavigate();

  const [isMounted, setIsMounted] = useState(false);
  
  const onFrameButtonClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const onFrameButton1Click = useCallback(() => {
    navigate("/diary");
  }, [navigate]);

  const onFrameButton2Click = useCallback(() => {
    navigate("/recipes");
  }, [navigate]);

  const onFrameButton3Click = useCallback(() => {
    navigate("/chat");
  }, [navigate]);

  const onFrameButton4Click = useCallback(() => {
    navigate("/profiel");
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const onVectorIconClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const [filterOn, setFilterOn] = useState(false);
  localStorage.setItem("Filter", filterOn);

  const onFilterButtonClick = useCallback(() => {
    setFilterOn(!filterOn);
    localStorage.setItem('Filter', filterOn);
  }, [filterOn]);

  const [searchQuery, setSearchQuery] = useState('');

  const handleRecipeClick = (recipeID) => {
    localStorage.setItem("recipeID", parseInt(recipeID));
    console.log(typeof JSON.parse(localStorage.getItem("recipeID")));
    navigate("/Recipes-content");
  };
 
  return (
    <div className="recipes">
      <div className="top-bar7">
        <button className="home1" onClick={onHomeClick}>
          <img
            className="vector-icon13"
            alt=""
            src="/vector8.svg"
            onClick={onVectorIconClick}
          />
        </button>
        <div className="name3">
          <div className="cibus7">CIBUS</div>
        </div>

        <img />
      </div>
      <div className="frame-parent8">
        <div className="frame-parent9">
          <input
            className="frame-child1"
            type="search"
            placeholder="Zoek een recept of ingrediënt"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            className={`filter2 ${filterOn ? "filter-on" : ""}`}
            onClick={onFilterButtonClick}
          >
            <div className="filter-on-climate">
              {" "}
              {filterOn ? "Gefilterd op klimaatimpact" : "Filter op klimaatimpact"}
            </div>
          </button>
        </div>
        <div className="season">
          <div className="circle"></div>
          <div className="seizoensgroenten">Bevat seizoensgroenten</div>
        </div>

        <div className="recipes1">
          <RecipeContainer
            onRecipeClick={handleRecipeClick}
            searchQuery={searchQuery}
          />
        </div>
      </div>
      <Footer
        dimensions="/piechartoutlined2.svg"
        dimensionsCode="/vector11.svg"
        dimensionsCode2="/-icon-fast-food-outline2.svg"
        dimensionsCode3="/-icon-chatbubble-ellipses-outline2.svg"
        dimensionsCode4="/-icon-person1.svg"
        propAlignSelf="unset"
        propColor="#9f9f9f"
        propColor1="#9f9f9f"
        propColor2="#F66047"
        propColor3="#9f9f9f"
        onFrameButtonClick={onFrameButtonClick}
        onFrameButton1Click={onFrameButton1Click}
        onFrameButton2Click={onFrameButton2Click}
        onFrameButton3Click={onFrameButton3Click}
        onFrameButton4Click={onFrameButton4Click}
      />
    </div>
  );
};

export default Recipes;