import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IngredientsForm from "../components/IngredientsForm";
import "./RecipesContent.css";
import Image1 from "../Images/recept1.jpg";
import Image2 from "../Images/recept2.jpg";

const RecipesContent = () => {
  const navigate = useNavigate();
  const [recipeID, setRecipeID] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState("");
  const [recipeContent, setRecipeContent] = useState([]);

  useEffect(() => {
    setIsMounted(true);
    const storedRecipeID = localStorage.getItem("recipeID");
    setRecipeID(JSON.parse(storedRecipeID));
    console.log(typeof recipeID);

    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/recipes");
  }, [navigate]);

  
    useEffect(() => {
    if (isMounted) {
      console.log(recipeID);
      const recipes = JSON.parse(localStorage.getItem("recipes"));
      const Recipe = recipes.find((recipe) => recipe.id === recipeID);
      setSelectedRecipe(Recipe);
      console.log("recipe", selectedRecipe);
      
      
      //const ingredientsRec = JSON.parse(selectedRecipe.ingredients); // extract ingredients array from recipe object

      const stringIngr = JSON.stringify(Recipe.ingredients);
      const newArray = JSON.parse(stringIngr);
      //console.log(typeof newArray);
      console.log(newArray);

      const selectedRecipeIngredients = newArray.map((ingredient) => ({
        name: ingredient[0],
        quantity: ingredient[1],
        climateImpact: ingredient[2],
        seasonal: ingredient[3],
  
      }));
      console.log(selectedRecipeIngredients);

      setRecipeContent(
        selectedRecipeIngredients.map((ingredient) => (
          <section className="ingredient62" id={ingredient.id}>
            <p className="kcal62" id="WeightIngredient2">
              {ingredient.quantity}
            </p>
            <p className="kcal62" id="Ingredient2">
              {ingredient.name}
            </p>
            <p
              className={`kcal62 ${
                ingredient.seasonal === "Ja" ? "circle" : ""
              }`}
              id="seasonal"
            >
            </p>

            <p className="kcal62" id="ClimateImpactIngredient2">
              {ingredient.climateImpact}
            </p>
          </section>
        ))
      );
    }
    }, [isMounted, recipeID]);
   const [showIngredients, setShowIngredients] = useState(true);
   const [showPreparation, setShowPreparation] = useState(false);

   const handleIngredientsClick = () => {
     setShowIngredients(!showIngredients);
     setShowPreparation(false);
   };

   const handlePreparationClick = () => {
     setShowPreparation(!showPreparation);
     setShowIngredients(false);
   };

  return (
    <div className="recipes-content">
      <div className="top-bar4">
        <button
          className="baselectedRecipeck-frame"
          onClick={onFrameButtonClick}
        >
          <img className="back-icon2" alt="" src="/back.svg" />
        </button>
        <div className="cibus-wrapper1">
          <div className="cibus4">CIBUS</div>
        </div>
        <img className="vector-icon10" alt="" src="/vector.svg" />
      </div>

      {selectedRecipe && (
        <div key={selectedRecipe.id} className="recipe-card">
          <div className="recipes-content-inner">
            <img className="recipe-image" alt="" src={selectedRecipe.image} />
          </div>
          <div className="frame-parent7">
            <div className="parent1">
              <div className="div1">
                
                <p>
                  {selectedRecipe.co2} kg CO <sub>2</sub>
                </p>
              </div>
            </div>
            <div className="parent1">
              <div className="div1">{selectedRecipe.time}</div>
            </div>
            <div className="parent1">
              <div className="div1">{selectedRecipe.personen}</div>
            </div>
          </div>
          <div className="naam-recept">
            <div className="cibus4">{selectedRecipe.title}</div>
          </div>
          <div className="buttons_click">
            <button
              className={`toggle-button2 ${showIngredients ? "active" : ""}`}
              onClick={handleIngredientsClick}
            >
              Ingrediënten
            </button>
            <button
              className={`toggle-button2 ${showPreparation ? "active" : ""}`}
              onClick={handlePreparationClick}
            >
              Bereiden
            </button>
          </div>
          {showIngredients && (
            <div className="organize">
              <div className="ingredient-group2">
                <section className="ingredient62">
                  <p className="kcal62" id="WeightIngredient2">
                    <b></b>
                  </p>
                  <p className="kcal62" id="Ingredient2">
                    <b>Ingrediënt</b>
                  </p>

                  <p className="kcal62" id="ClimateImpactIngredient2">
                    <b>
                      Impact (g CO<sub>2</sub>)
                    </b>
                  </p>
                </section>
                {recipeContent}
              </div>
            </div>
          )}
          {showPreparation && (
            <div className="organize2">
              <div className="bereiding">
                <p className="header-bereiding">Bereiding:</p>
              </div>
              <ol className="stappen">
                {selectedRecipe.bereidingswijze.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ol>
            </div>
          )}
        </div>
      )}
    </div>
  );
};  

export default RecipesContent;
