import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Opslaan.css";

const Opslaan = ({ onClose }) => {
  const navigate = useNavigate();

  const onKruisButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="gegevens-vergeten3">
  
      <b className="gegevens-vergeten1">Jouw gegevens zijn succesvol opgeslagen!</b>
      <img class="save_image" alt="" src="/save.svg"></img>
      {/* <div className="gebruik-de-persoonlijke-container">
        <p className="gebruik-de-persoonlijke">
          Gebruik de persoonlijke gebruikersnaam + wachtwoord die voor jou is
          gemaakt
        </p>
        <p className="gebruik-de-persoonlijke">
          Mocht je de gegevens toch niet meer kunnen vinden, dan kan je altijd
          contact opnemen met de onderzoeker:
        </p>
        <p className="gebruik-de-persoonlijke">Britt Stegers</p>
        <p className="gebruik-de-persoonlijke">+31621938925</p>
      </div> */}
    </div>
  );
};

export default Opslaan;
