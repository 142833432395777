import "./ChatContainer.css";
import React, { useState, useCallback } from 'react';
import axios from "axios";
var OOCSI = require('oocsi')



const ChatContainer = ({ messages, onSendMessage }) => {
  const [newMessage, setNewMessage] = useState("");

  const handleMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleMessageSend = () => {
    const message = {
      question: newMessage,
      answer: "",
    };

    setNewMessage("");
    OOCSI.connect("wss://oocsi.id.tue.nl/ws");

    var data = {
     "input": newMessage
    };

    OOCSI.send("Chat_Food_API10", data);

    OOCSI.subscribe("GPT_Chatting_food", function(msg) {
      const newAnswer = msg.data.text;
      const updatedMessage = {...message, answer: newAnswer};
      onSendMessage(updatedMessage);
    });
    
   onSendMessage(message);
    //const messages = newMessage;
    // const url = "https://data.id.tue.nl/api/vendor/openai/3490";
    // const data = {
    //   "api_token": "df-blNsZUNUSVNMbzFpa3puVEV0aGdjdEQ5RHlHbWdEc2JNRldyd3UxYWtpcz0=",
    //   "task": "chat",
    //   "model": "ada",
    //   "messages": [
    // { "role": "user", "content": newMessage}]
    // };
    // axios
    //   .post(url, data, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response.data.content);
    //     const updatedMessage = {
    //       question: newMessage,
    //       answer: response.data.content,
    //     };
    //     onSendMessage(updatedMessage);
    //     console.log(onSendMessage);
    //   })
    // .catch((error) => {
    //     console.error(error);
    //     // handle the error here
    //   });
      
      
    // onSendMessage(message);

    
  };

  return (
    <>
      <div className="chat-container">
        {messages.map((message, index) => (
          <div key={index}>
            {message.question &&(
            <div  className="chat-message">{message.question}</div>
        )}
            {message.answer && (
              <div className="chat-answer">{message.answer}</div>
            )}
          </div>
        ))}
      </div>
      <div className="chat-input-container">
        <textarea
          type="text"
          className="chatvraag"
          value={newMessage}
          onChange={handleMessageChange}
          placeholder="Stel een vraag..."
        />
        <button className="sending" onClick={handleMessageSend}>
          <img className="send-icon" alt="" src="/send.svg" />
        </button>
      </div>
    </>
  );
};

export default ChatContainer;
