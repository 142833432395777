import { useState, useMemo } from "react";
import "./RecipeContainer.css";
import Image1 from "../Images/recept1.jpg";
import Image2 from "../Images/recept2.jpg";
import Image3 from "../Images/recept3.jpg";
import Image4 from "../Images/recept4.jpg";
import Image5 from "../Images/recept5.jpg";
import Image6 from "../Images/recept6.jpg";
import Image7 from "../Images/recept7.jpg";
import Image8 from "../Images/recept8.jpg";
import Image9 from "../Images/recept9.jpg";
import Image10 from "../Images/recept10.jpg";
import Image11 from "../Images/recept11.jpg";
import Image12 from "../Images/recept12.jpg";
import Image13 from "../Images/recept9.jpg";
import Image14 from "../Images/recept14.jpg";
import Image15 from "../Images/recept15.jpg";
import Image17 from "../Images/recept17.jpg";
import Image18 from "../Images/recept18.jpg";
import Image20 from "../Images/recept20.jpg";
import Image21 from "../Images/recept21.jpg";
import Image22 from "../Images/recept22.jpg";


import React from 'react';
import { useNavigate } from "react-router-dom";

const recipes = [
  {
    id: 0,
    image: Image1,
    title: "Spaghetti Bolognese",
    time: "30-40 min",
    co2: 17.9,
    personen: "4 personen",
    ingredients: [
      ["Spaghetti", " 500 g", 762, "Nee"],
      ["Rundergehakt", "500 g", 15013, "Nee"],
      ["Ui", 1, 78, "Ja"],
      ["Knoflookteentjes", 2, "", "Nee"],
      ["Tomatenpuree", "70 g", 81, "Ja"],
      ["Gepelde tomaten (blik)", "800 g", 2007, "Ja"],
      ["Suiker", "1 tl", 10, ""],
      ["Oregano", "1 tl", "", ""],
      ["Basilicum", "1 tl", "", ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Bak het gehakt in een grote pan op hoog vuur tot het bruin is.",
      "Voeg de ui en knoflook toe en bak nog 2 minuten.",
      "Voeg de tomatenpuree toe en bak nog 2 minuten.",
      "Voeg de gepelde tomaten toe en breek ze in kleinere stukken met een lepel.",
      "Voeg suiker, oregano, basilicum, zout en peper toe. Roer goed door.",
      "Laat de saus 15-20 minuten sudderen op laag vuur terwijl je de spaghetti kookt.",
      "Giet de spaghetti af en serveer de saus over de gekookte spaghetti",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 1,
    image: Image2,
    title: "Rijst met kip en groenten",
    time: "30-40 min",
    co2: 7.7,
    personen: "4 personen",
    ingredients: [
      ["Kipfilet (blokjes)", "300 g", 3261, ""],
      ["Rijst", "300 g", 535, ""],
      ["Ui", 1, 78, "Ja"],
      ["Knoflookteentjes", 2, "", ""],
      ["Paprika rood", 1, 608, ""],
      ["Paprika geel", 1, 608, ""],
      ["Paprika groen", 1, 608, ""],
      ["Maïs", "140 g", 227, ""],
      ["Kidneybonen", "400 g", 753, ""],
      ["Tomatenblokjes (blik)", "400 g", 1003, "Ja"],
      ["Komijnpoeder", "", "", ""],
      ["Paprikapoeder", "", "", ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],

    seasonal: true,
    bereidingswijze: [
      "Bak de kipfilet in een grote pan op hoog vuur tot het bruin is.",
      "Voeg de ui en knoflook toe en bak nog 2 minuten.",
      "Voeg de paprika's, mais en kidneybonen toe en bak nog 5 minuten.",
      "Voeg de tomatenblokjes, komijnpoeder, paprikapoeder, zout en peper toe en roer goed door.",
      "Laat het geheel 15-20 minuten sudderen op laag vuur terwijl je de rijst kookt.",
      "Serveer de kip-groentenmix over de gekookte rijst.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 2,
    image: Image3,
    title: "Gevulde portobello champignons",
    time: "30-35 min",
    co2: 8.6,
    personen: "4 personen",
    ingredients: [
      ["Grote portobello champignons", 8, 5208, "Ja"],
      ["Paprika rood", 2, 1216, ""],
      ["Ui", 2, 156, "Ja"],
      ["Knoflookteentjes", 2, "", ""],
      ["Broodkruimels", "1 kopje", 123, ""],
      ["Parmezaanse kaas", "1 kopje", 1571, ""],
      ["Olijfolie", "4 el", 286, ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Verwarm de oven voor op 180 graden.",
      "Maak de portobello champignons schoon en verwijder de stelen. Leg ze op een bakplaat.",
      "Verhit de olijfolie in een pan op middelhoog vuur. Bak de ui, knoflook en paprika tot ze zacht zijn.",
      "Voeg de broodkruimels en Parmezaanse kaas toe aan het mengsel en roer goed door.",
      "Vul de portobello champignons met het mengsel en bestrooi ze met zout en peper.",
      "Bak de champignons gedurende 20-25 minuten, of tot ze zacht zijn en de vulling goudbruin is.",
      "Je zou het kunnen serveren met een simpele salade of gebakken aardappeltjes als je wat extra's wilt toevoegen.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 3,
    image: Image4,
    title: "Gehaktballen met aardappelpuree en rode kool",
    time: "45 min",
    co2: 17.3,
    personen: "4 personen",
    ingredients: [
      ["Rundergehakt", "500 g", 15013, ""],
      ["Ei", 1, 250, ""],
      ["Ui", 1, 78, "Ja"],
      ["Paneermeel", 2, "", ""],
      ["Aardappelen", "1 kg", 923, "Ja"],
      ["Rode kool", "500 g", 300, ""],
      ["Boter", "50 g", 611, ""],
      ["Melk", "50 ml", 101, ""],
      ["Azijn", "1 el", "", ""],
      ["Suiker", "2 el", 21, ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Meng het gehakt met het ei, de ui en paneermeel en breng op smaak met peper en zout.",
      "Vorm kleine gehaktballen van het mengsel en bak ze in een koekenpan tot ze gaar zijn.",
      "Kook de aardappelen in een pan met water tot ze zacht zijn.",
      "Verwarm de rode kool in een pan met boter, azijn en suiker.",
      "Giet de aardappelen af en stamp ze fijn met een stamper.",
      "Voeg de melk en boter toe en meng tot een gladde puree.",
      "Serveer de gehaktballen met de aardappelpuree en rode kool.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 4,
    image: Image5,
    title: "Gele curry met kip en groenten",
    time: "30 min",
    co2: 8.9,
    personen: "4 personen",
    ingredients: [
      ["Kipfilet (blokjes)", "400 g", , ""],
      ["Kokosmelk", "400 ml", , ""],
      ["Gele currypasta", "1 el", , ""],
      ["Ui", "1 ", 78, "Ja"],
      ["Teentjes knoflook", "2", , ""],
      ["Rode paprika", 1, , ""],
      ["Courgette", 1, , ""],
      ["Wortel", 1, , "Ja"],
      ["Kikkererwten", "400 g", ""],
      ["Olijfolie", "1 el", , ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
      ["Zilvervliesrijst", "400 g", 658, ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Kook de rijst volgens de instructies op de verpakking.",
      "Verhit de olie in een grote koekenpan en fruit hierin de ui en knoflook tot ze zacht zijn.",
      "Voeg de kip toe en bak tot deze goudbruin is.",
      "Voeg de currypasta toe en bak nog 1 minuut.",
      "Voeg de paprika, courgette en wortel toe en bak tot de groenten zacht zijn.",
      "Voeg de kikkererwten en kokosmelk toe en roer goed door.",
      "Laat het geheel nog 5 minuten sudderen op laag vuur.",
      "Breng op smaak met zout en peper en serveer de curry met de rijst.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 5,
    image: Image6,
    title: "Boerenkoolstamppot met rookworst",
    time: "45-60 min",
    co2: 9.6,
    personen: "4 personen",
    ingredients: [
      ["Aardappelen", "1500 g", 1384, "Ja"],
      ["Boerenkool", "500 g", 799, ""],
      ["Rookworst", "285 g", 6232, ""],
      ["Ui", "1 ", 78, "Ja"],
      ["Boter", "50 g", 611, ""],
      ["Melk", "250 ml", 512, ""],
      ["Teentjes knoflook", "1", , ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Doe de aardappelen in een grote pan en voeg water toe totdat ze net onderstaan. Voeg wat zout toe aan het water en breng aan de kook.",
      "Zet het vuur laag en laat de aardappelen ongeveer 20 minuten koken, of totdat ze zacht zijn.",
      "Verhit ondertussen een beetje olie in een grote koekenpan en fruit de ui en knoflook tot ze zacht zijn.",
      "Voeg de boerenkool toe en bak het mee tot het geslonken is en gaar is.",
      "Verwarm de rookworsten volgens de aanwijzingen op de verpakking.",
      "Giet de aardappelen af en stamp ze fijn. Voeg de melk en boter toe en roer goed door elkaar.",
      "Voeg de gebakken boerenkool en ui toe aan de aardappelen en roer nogmaals goed door. Breng op smaak met zout en peper.",
      "Serveer de stamppot met de rookworsten en eventueel wat mosterd.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 6,
    image: Image7,
    title: "Curry met kikkererwten en spinazie",
    time: "25-30 min",
    co2: 4.1,
    personen: "4 personen",
    ingredients: [
      ["Witte rijst", "400 g", 713, ""],
      ["Tomatenblokjes", "400 g", 1003, "Ja"],
      ["Spinazie", "200 g", 445, "Ja"],
      ["Kikkererwten", "400 g", 1491, ""],
      ["Kokosmelk", "200 ml", 262, ""],
      ["Limoen", "1/2", 34, ""],
      ["Rode peper", "1", 11, ""],
      ["Olijfolie", "1 el", 71, ""],
      ["Ui", "1 ", 78, "Ja"],
      ["Teentjes knoflook", "2", "", ""],
      ["Gember", "1 tl", "", ""],
      ["Koriander", "2 tl", "", ""],
      ["Komijn", "1 tl", "", ""],
      ["Kurkuma", "1/2 tl", "", ""],
      ["Verse koriander", "1", "", ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Verhit de olie in een grote pan en fruit de ui, knoflook, rode peper en gember tot ze zacht zijn.",
      "Voeg de gemalen koriander, komijn en kurkuma toe en bak 1-2 minuten tot de kruiden beginnen te geuren.",
      "Voeg de tomatenblokjes toe en laat 5-10 minuten zachtjes sudderen tot de saus iets is ingedikt.",
      "Voeg de kikkererwten toe en laat 5 minuten sudderen tot ze warm zijn.",
      "Voeg de spinazie toe en laat het slinken in de saus.",
      "Voeg de kokosmelk en het limoensap toe en laat het nog 2-3 minuten zachtjes pruttelen.",
      "Breng op smaak met zout en peper en serveer de curry met rijst en garneer met verse koriander.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 7,
    image: Image8,
    title: "Roerbak van sperziebonen en kip",
    time: "20-25 min",
    co2: 4.0,
    personen: "4 personen",
    ingredients: [
      ["Sperziebonen", "500 g", 530, "Ja"],
      ["Kipfilet", "250 g", 2718, ""],
      ["Paprika rood", "1", 608, ""],
      ["Ui", "1", 78, "Ja"],
      ["Sojasaus", "2 el", 33, ""],
      ["Teentjes knoflook", "2", "", ""],
      ["Olijfolie", "1 el", 71, ""],
      ["Maizena", "1 tl", , ""],
      ["Suiker", "1 tl", 10, ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Kook de sperziebonen in een pan met water in ongeveer 5 minuten beetgaar.",
      "Verhit de olijfolie in een wok of grote koekenpan op hoog vuur.",
      "Bak de kipfilet goudbruin in de pan, haal deze uit de pan en zet opzij.",
      "Voeg de ui, knoflook en paprika toe aan de pan en bak deze tot ze zacht zijn.",
      "Voeg de sperziebonen en kip weer toe aan de pan.",
      "Meng in een kom de sojasaus, maizena en suiker tot een glad mengsel.",
      "Voeg het mengsel toe aan de pan en roerbak alles nog 2-3 minuten tot de saus indikt.",
      "Breng op smaak met peper en zout.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 8,
    image: Image9,
    title: "Risotto met champignons en parmezaanse kaas",
    time: "40-45 min",
    co2: 5.0,
    personen: "4 personen",
    ingredients: [
      ["Risottorijst", "300 g", 535, ""],
      ["Champignons", "500 g", 2604, "Ja"],
      ["Ui", "1", 78, "Ja"],
      ["Parmezaanse kaas", "100 g", 1309, ""],
      ["Bouillonblokjes  (1L water)", 44, , ""],
      ["Teentjes knoflook", "2", "", ""],
      ["Olijfolie", "2 el", 143, ""],
      ["Boter", "25 g", 305, ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Verhit de olijfolie en boter in een grote pan op middelhoog vuur. Voeg de ui en knoflook toe en bak tot ze zacht zijn.",
      "Voeg de risottorijst toe en roer goed door. Bak de rijst al roerend voor ongeveer 2 minuten tot deze glazig wordt.",
      "Voeg de champignons toe en bak deze voor ongeveer 5 minuten tot ze zacht zijn en het vocht verdampt is.",
      "Voeg nu een soeplepel bouillon toe en blijf roeren tot de bouillon is opgenomen. Herhaal dit proces totdat alle bouillon is gebruikt en de rijst gaar is. Dit duurt ongeveer 20-25 minuten.",
      "Voeg op het einde de Parmezaanse kaas toe en roer door totdat deze is gesmolten. Breng op smaak met zout en peper.",
      "Serveer de risotto direct met extra Parmezaanse kaas en verse peterselie.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 9,
    image: Image10,
    title: "Geroosterde groenten met turks brood en tzaziki",
    time: "40 min",
    co2: 6.4,
    personen: "4 personen",
    ingredients: [
      ["Courgette", "2", 2107, ""],
      ["Paprika's", "2", 1216, ""],
      ["Aubergine", "1", 763, ""],
      ["Rode ui", "1", 78, "Ja"],
      ["Olijfolie", "3 el", 215, ""],
      ["Turks brood", "1", 595, ""],
      ["Komkommer", "1", 751, ""],
      ["Griekse yoghurt", "250 ml", 588, ""],
      ["Citroensap", "1 el", 51, ""],
      ["Dille", "1 el", "", ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: false,
    bereidingswijze: [
      "Verwarm de oven voor op 200°C.",
      "Snijd de courgettes, paprika's, aubergine en rode ui in plakjes of reepjes.",
      "Leg de gesneden groenten op een bakplaat en besprenkel ze met olijfolie. Breng op smaak met zout en peper.",
      "Rooster de groenten in de voorverwarmde oven gedurende ongeveer 25-30 minuten, of tot ze zacht en licht gebruind zijn. Keer de groenten halverwege de baktijd om.",
      "Terwijl de groenten in de oven staan, kun je het Turks brood in de oven verwarmen volgens de instructies op de verpakking.",
      "Haal de geroosterde groenten uit de oven en laat ze iets afkoelen.",
      "Voor de tzatziki, rasp de komkommer en knijp het overtollige vocht eruit met je handen.",
      "Meng de geraspte komkommer, Griekse yoghurt, knoflook, olijfolie en citroensap in een kom. Roer goed door.",
      "Voeg de fijngehakte dille toe en breng op smaak met zout en peper.",
      "Serveer de geroosterde groenten samen met het Turks brood en tzatziki.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 10,
    image: Image11,
    title: "Gnocchi met tomatensaus en mozzarella",
    time: "30 min",
    co2: 2.3,
    personen: "4 personen",
    ingredients: [
      ["Gnocchi", "500 g", 611, ""],
      ["Ui", "1", 78, "Ja"],
      ["Gepelde tomaten", "400 g", 467, ""],
      ["Mozarrella", "1 bol", 1057, ""],
      ["Teentjes knoflook", "2", "", ""],
      ["Olijfolie", "1 el", 71, ""],
      ["Oregano", "1 tl", "", ""],
      ["Basilicum", "1 tl", "", ""],
      ["Suiker", "1 tl", 8, ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: false,
    bereidingswijze: [
      "Breng een grote pot gezouten water aan de kook. Kook de gnocchi volgens de instructies op de verpakking. Giet de gnocchi af en zet apart.",
      "Verhit een scheut olijfolie in een pan op middelhoog vuur. Voeg de ui en knoflook toe en bak tot ze zacht zijn.",
      "Voeg de gepelde tomaten, oregano, basilicum en suiker toe aan de pan. Roer goed door en breng aan de kook.",
      "Laat de tomatensaus 10 minuten sudderen op laag vuur. Breng op smaak met zout en peper.",
      "Voeg de gekookte gnocchi toe aan de pan en roer voorzichtig door de tomatensaus. Voeg de mozzarella toe en laat nog een paar minuten op laag vuur staan totdat de mozzarella is gesmolten.",
      "Serveer heet met geraspte Parmezaanse kaas, indien gewenst. (100 g parmezaanse kaas vehoogt de klimaatimpact met ongeveer 1309 g CO2",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 11,
    image: Image12,
    title: "Pastasalade pesto",
    time: "20 min",
    co2: 2.0,
    personen: "4 personen",
    ingredients: [
      ["Pasta", "400 g", 609, ""],
      ["Cherrytomaten", "250 g", 448, "Ja"],
      ["Groene pesto", "100 g", "", ""],
      ["Komkommer", "1/2", 375, ""],
      ["Rode ui", "1/2", 21, "Ja"],
      ["Zwarte olijven", "50 g", 125, ""],
      ["Feta kaas", "50 g", 425, ""],
      ["Verse basilicum", "", "", ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Kook de pasta volgens de instructies op de verpakking. Giet af en spoel af met koud water om af te koelen.",
      "In een grote kom, meng de gekookte pasta met de groene pesto tot de pasta gelijkmatig bedekt is met de pesto.",
      "Voeg de cherrytomaten, komkommer, rode ui, zwarte olijven en feta-kaas toe aan de kom. Meng goed door elkaar.",
      "Scheur de basilicumblaadjes in stukjes en voeg ze toe aan de salade. Breng op smaak met zout en peper naar smaak.",
      "Serveer de pastasalade direct of zet hem in de koelkast om af te koelen voordat je hem serveert.",
      "Geniet van de heerlijke pastasalade met pesto als hoofdgerecht of als bijgerecht bij een zomerse maaltijd.",
    ],
  },

  {
    id: 12,
    image: Image13,
    title: "Spaghetti carbonara",
    time: "20 min",
    co2: 3.4,
    personen: "4 personen",
    ingredients: [
      ["Spaghetti", "400 g", 609, ""],
      ["Eieren", "4", 1002, ""],
      ["Spekreepjes", "100 g", 1098, ""],
      ["Ui", "1", 78, "Ja"],
      ["Parmezaanse kaas", "50 g", 654, ""],
      ["Olijfolie", "2 el", 143, ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: false,
    bereidingswijze: [
      "Kook de spaghetti volgens de instructies op de verpakking.",
      "Verhit de olijfolie in een pan en bak de spekreepjes tot ze goudbruin en knapperig zijn.",
      "Voeg de ui toe en bak tot deze zacht is.",
      "Klop ondertussen in een kom de eieren los.",
      "Voeg de geraspte Parmezaanse kaas en zout en peper naar smaak toe aan de kom met eieren.",
      "Giet de spaghetti af en voeg deze toe aan de pan met spekreepjes en ui.",
      "Haal de pan van het vuur en voeg het eimengsel toe. Roer goed door elkaar totdat het eimengsel licht gestold is.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 13,
    image: Image14,
    title: "Gevulde paprika's met gehakt en rijst",
    time: "50  min",
    co2: 9.8,
    personen: "4 personen",
    ingredients: [
      ["Paprika rood", "4", 2003, ""],
      ["Gehakt half-om-half", "300 g", 6111, ""],
      ["Rijst", "100 g", 178, ""],
      ["Ui", "1", 78, "Ja"],
      ["Tomatenpuree", "70 g", 81, ""],
      ["Bruine bonen", "400 g", 755, ""],
      ["Paprikapoeder", "1 tl", "", ""],
      ["Komijn", "1 tl", "", ""],
      ["Geraspte kaas", "50 g", 550, ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: false,
    bereidingswijze: [
      "Verwarm de oven voor op 180 graden Celsius.",
      "Snipper de ui en hak de knoflook fijn.",
      "Kook de rijst volgens de verpakking gaar.",
      "Verhit een scheutje olie in een pan en bak de ui en knoflook tot ze zacht zijn.",
      "Voeg het gehakt toe en bak het rul.",
      "Voeg de tomatenpuree, bruine bonen, paprikapoeder en komijn toe en roer goed door.",
      "Breng op smaak met zout en peper.",
      "Snijd de bovenkant van de paprika's en verwijder de zaadlijsten.",
      "Vul de paprika's met het gehaktmengsel en zet ze in een ovenschaal.",
      "Bestrooi de gevulde paprika's met geraspte kaas.",
      "Bak de paprika's in 30 minuten gaar in de oven.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 14,
    image: Image15,
    title: "Gegrilde Kip Gyros Pita Broodjes",
    time: "30 min",
    co2: 8.1,
    personen: "4 personen",
    ingredients: [
      ["Kipfilet", "500 g", 5436, ""],
      ["Rode ui", "1", 43, "Ja"],
      ["Komkommer", "1/2", 375, ""],
      ["Tomaten", "4", 501, "Ja"],
      ["Feta", "100 g", 850, ""],
      ["Griekse yoghurt", "100 ml", 235, ""],
      ["Teentje knoflook", "1", "", ""],
      ["Oregano", "1 tl", "", ""],
      ["Tijm", "1 tl", "", ""],
      ["Gerookte paprikapoeder", "1 tl", "", ""],
      ["Cayennepeper", "1/4 tl", "", ""],
      ["Zout", "1/4 tl", "", ""],
      ["Peper", "1/4 tl", "", ""],
      ["Olijfolie", "2 el", 143, ""],
      ["Pitabroodjes", "8", 476, ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Meng de kipreepjes met rode ui, knoflook, oregano, tijm, paprikapoeder, cayennepeper, zout en zwarte peper.",
      "Verhit de olijfolie in een grote koekenpan en bak de kipreepjes in ongeveer 10 minuten goudbruin en gaar.",
      "Verwarm de pitabroodjes in de oven of broodrooster.",
      "Vul de pitabroodjes met kipreepjes, tomaten, komkommer, feta en een lepel Griekse yoghurt.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 15,
    image: Image17,
    title: "Couscoussalade met aubergine",
    time: "30 min",
    co2: 4.2,
    personen: "4 personen",
    ingredients: [
      ["Couscous", "300 g", 493, ""],
      ["Aubergine", "1", 790, ""],
      ["Paprika rood", "1", 608, ""],
      ["Courgette", "1", 526, ""],
      ["Rode ui", "1", 78, "Ja"],
      ["Kikkererwten", "400 g", 1491, ""],
      ["Teentjes knoflook", "2", "", ""],
      ["Olijfolie", "2 el", 143, ""],
      ["Komijnpoeder", "1 tl", "", ""],
      ["Paprikapoeder", "1 tl", "", ""],
      ["Kurkumapoeder", "1/2 tl", "", ""],
      ["Kaneelpoeder", "1/2 tl", "", ""],
      ["Citroen (sap)", "1", 74, ""],
      ["Peterselie", "(handje)", "", ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: false,
    bereidingswijze: [
      "Bereid de couscous volgens de instructies op de verpakking.",
      "Snijd de aubergine, rode paprika, courgette en rode ui in blokjes.",
      "Verhit 1 eetlepel olijfolie in een grote pan op middelhoog vuur. Voeg de gesneden groenten toe en bak ze gedurende 8-10 minuten, of tot ze zacht en licht gebruind zijn.",
      "Voeg de fijngehakte knoflook, komijnpoeder, paprikapoeder, kurkumapoeder en kaneelpoeder toe aan de pan. Bak nog eens 1-2 minuten om de kruiden te laten ontwikkelen.",
      "Voeg de kikkererwten toe aan de pan en roer goed door.",
      "Meng de gekookte couscous met het groentenmengsel in de pan. Giet het citroensap erover en voeg de fijngehakte peterselie toe. Roer alles goed door elkaar.",
      "Breng de couscous op smaak met zout en peper naar smaak.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 16,
    image: Image18,
    title: "Witlofsalade met mandarijn",
    time: "15 min",
    co2: 4.3,
    personen: "4 personen",
    ingredients: [
      ["Witlofstronken", "8", 325, ""],
      ["Mandarijnen", "4", 276, ""],
      ["Walnoten", "200 g", 661, ""],
      ["Blauwe kaas", "200 g", 2618, ""],
      ["Olijfolie", "6 el", 430, ""],
      ["Witte wijnazijn", "4 el", "", ""],
      ["Honing", "2 tl", 8, ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
    ],
    seasonal: false,
    bereidingswijze: [
      "Snijd de witlofstronken in dunne reepjes en doe ze in een grote saladekom.",
      "Pel de mandarijnen en verdeel de partjes over de witlof.",
      "Voeg de gehakte walnoten en verkruimelde blauwe kaas toe aan de saladekom.",
      "In een apart kommetje, meng je de olijfolie, witte wijnazijn, honing, zout en peper tot een dressing.",
      "Giet de dressing over de salade en meng alles goed door elkaar, zodat alle ingrediënten gelijkmatig bedekt zijn.",
      "Voeg eventueel gekookte kipfilet, gekookte aardappelen of stukjes brood toe om de salade vullender te maken.",
      "Serveer de witlofsalade met mandarijn als hoofdgerecht tijdens de avondmaaltijd.",
      "Eetsmakelijk!",
    ],
  },

  {
    id: 17,
    image: Image20,
    title: "Bami met kip",
    time: "30 min",
    co2: 5.0,
    personen: "4 personen",
    ingredients: [
      ["Bami", "250 g", 446, ""],
      ["Kipfilet (reepjes)", "300 g", 3261, ""],
      ["Paprika rood", "1", 608, ""],
      ["Wortels", "100 g", 77, "Ja"],
      ["Taugé", "100 g", 268, ""],
      ["Sojasaus", "2 el", 66, ""],
      ["Oestersaus", "1 el", 33, ""],
      ["Sambal", "1 tl", "", ""],
      ["Olijfolie", "2 el", 143, ""],
      ["Ui", "1", 78, "Ja"],
      ["Teentjes knoflook", "2", "", ""],
      ["Zout", "", "", ""],
      ["Peper", "", "", ""],
      [
        "Optionele toppings: fijngehakte lente-ui, gehakte pinda's, gebakken uitjes",
        "",
        "",
        "",
      ],
    ],
    seasonal: true,
    bereidingswijze: [
      "Kook de bami volgens de instructies op de verpakking. Giet af en spoel af met koud water om af te koelen. Zet apart. ",
      "Verhit de olie in een wok of grote pan op middelhoog vuur. ",
      "Voeg de knoflook en ui toe en bak tot ze zacht en geurig zijn. Voeg de kipfiletreepjes toe en bak tot ze gaar en lichtbruin zijn",
      "Voeg de rode paprika, wortels en taugé toe aan de pan. Roerbak gedurende 2-3 minuten, of tot de groenten iets zachter worden maar nog steeds knapperig zijn.",
      "Voeg de gekookte bami toe aan de pan en meng goed met de kip en groenten.",
      "Voeg de sojasaus, oestersaus en sambal toe. Roerbak alles samen gedurende nog eens 2-3 minuten, zodat de smaken goed kunnen mengen. ",
      "Breng de bami op smaak met zout en peper naar smaak. Serveer de bami warm met optionele toppings zoals fijngehakte lente-ui, gehakte pinda's en gebakken uitjes.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 18,
    image: Image21,
    title: "Mexicaanse wraps met gehakt",
    time: "30 min",
    co2: 10.1,
    personen: "4 personen",
    ingredients: [
      ["Rundergehakt", "300 g", 9007, ""],
      ["Paprika rood", "1", 608, ""],
      ["Ui", "1", 78, "Ja"],
      ["Teentjes knoflook", "2", "", ""],
      ["Cherrytomaten", "100 g", 250, "Ja"],
      ["Maïs", "100 g", 162, ""],
      ["Chilipoeder", "1 tl", "", ""],
      ["Komijnpoeder", "1 tl", "", ""],
      ["Paprikapoeder", "1/2 tl", "", ""],
      ["Oregano", "1/2 tl", "", ""],
    ],
    seasonal: true,
    bereidingswijze: [
      "Verhit een beetje olie in een pan op middelhoog vuur en bak het rundergehakt rul en bruin.",
      "Haal het gehakt uit de pan en zet apart. In dezelfde pan, voeg de ui, knoflook, rode paprika, chilipoeder, komijnpoeder, paprikapoeder en oregano toe.",
      "Bak tot de groenten zacht zijn. Voeg het gebakken gehakt weer toe aan de pan en meng goed. ",
      "Verwarm de tortillawraps volgens de instructies op de verpakking.",
      "Verdeel het gehakt-groentemengsel gelijkmatig over de tortillawraps. Voeg cherrytomaten en maïskorrels toe aan de wraps. ",
      "Rol de wraps strak op en serveer ze direct. Garneer de wraps met optionele toppings zoals gesneden jalapeño pepers, zure room, guacamole en salsa.",
      "Eetsmakelijk!",
    ],
  },
  {
    id: 19,
    image: Image22,
    title: "Nachoschotel met kip",
    time: "30 min",
    co2: 6.9,
    personen: "4 personen",
    ingredients: [
      ["Tortillachips", "200 g", 966, ""],
      ["Kipfilet (blokjes)", "300 g", 3261, ""],
      ["Paprika rood", "1", 608, ""],
      ["Geraspte kaas", "150 g", 1963, ""],
      ["Ui", "1", 78, "Ja"],
      ["Teentjes knoflook", "2", "", ""],
      ["Chilipoeder", "1 tl", "", ""],
      ["Komijnpoeder", "1 tl", "", ""],
      ["Paprikapoeder", "1/2 tl", "", ""],
      ["Oregano", "1/2 tl", "", ""],

      [
        "Optionele toppings: gehakte tomaten, jalapeño pepers, zure room, guacamole, salsa",
        "",
        "",
        "",
      ],
    ],
    seasonal: false,
    bereidingswijze: [
      "Verwarm de oven voor op 200°C.",
      "Verhit een beetje olie in een pan op middelhoog vuur en bak de kipfiletblokjes goudbruin en gaar. Haal de kip uit de pan en zet apart.",
      "In dezelfde pan, voeg de ui, knoflook, rode paprika, chilipoeder, komijnpoeder, paprikapoeder en oregano toe. Bak tot de groenten zacht zijn.",
      "Voeg de gebakken kip weer toe aan de pan en meng goed.",
      "Leg de tortillachips in een enkele laag op een bakplaat of in een ovenschaal.",
      "Verdeel het kip-groentemengsel gelijkmatig over de tortillachips.",
      "Bestrooi de nachoschotel met geraspte kaas.",
      "Bak de nachoschotel in de voorverwarmde oven gedurende ongeveer 10-15 minuten, of tot de kaas gesmolten en lichtbruin is.",
      "Haal de nachoschotel uit de oven en garneer met optionele toppings zoals gehakte tomaten, jalapeño pepers, zure room, guacamole en salsa.",
      "Eetsmakelijk!",
    ],
  },
];
localStorage.setItem("recipes", JSON.stringify(recipes));



const RecipeContainer = ({ onRecipeClick, searchQuery }) => {
  const navigate = useNavigate();
  
  // Check if filterOn is true in local storage
  const filterOn = localStorage.getItem("Filter") === "true";
  console.log(filterOn);
  //const recipe1Sort = recipes.sort((a, b) => a.id - b.id);
  // if (filterOn) {
  //   recipes.sort((a, b) => a.co2 - b.co2);
  // }

  // Select the recipe array based on filterOn
  const recipeArray = filterOn
    ? recipes.slice().sort((a, b) => a.co2 - b.co2)
    : recipes.slice().sort((a, b) => a.id - b.id);
  console.log(recipeArray);

  // Filter recipes based on search query
  const filteredRecipes = useMemo(() => {
    return recipeArray.filter((recipe) =>
      recipe.ingredients.some((ingredient) =>
        ingredient[0].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [recipeArray, searchQuery]);

  // Render recipe cards
  const renderRecipes = () => {
    return filteredRecipes.map((recipe, index) => (
      <div key={recipe.id} className="col-6">

          <section className="recipe" onClick={() => onRecipeClick(recipe.id)}>
            <div className="imagefood">
              <img className="imagefood-child" alt="" src={recipe.image} />
            </div>
            <div className="receipeinfo">
              <div className="text-container">
                <p className="text_content" id="StandardText">
                  {recipe.title}
                </p>
              </div>
              <div className="text-container">
                <div className="text_content">
                  <p className="p8">{recipe.time}</p>
                  <p className="p9">
                    {recipe.co2} kg CO<sub>2</sub>
                  </p>
                </div>
              </div>
              {recipe.seasonal && <div className="circle3"></div>}
            </div>
          </section>
        </div>
    ));
  };

  return (
    <div className="rowrecipe">
      {renderRecipes()
        .reduce((rows, recipeCard, i) => {
          if (i % 2 === 0) rows.push([]);
          rows[rows.length - 1].push(recipeCard);
          return rows;
        }, [])
        .map((row, i) => (
          <div className="rowrecipe" key={i}>
            {row}
          </div>
        ))}
    </div>
  );
};




export default RecipeContainer;
