import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IngredientSection from "../components/IngredientSection";
import "./DetailedDay.css";
import IngredientsFilter from "../components/IngredientsFilter";
var OOCSI = require("oocsi");
import Footer from "../components/Footer";

const DetailedDay = () => {
  const [selectedDate, setSelectedDate] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [recipeArray, setRecipeArray] = useState([]);

  const facts = JSON.parse(localStorage.getItem("myFacts"));
  // console.log("facts", JSON.parse(facts));

  const [counter, setCounter] = useState(0);
  const [ingredients, setIngredients] = useState([{ value: "", weight: "" }]);

  const [recipeName, setRecipeName] = useState("");

  const [listinfo, setListinfo] = useState([{ value: "", label: "" }]);
  const [joinedDinner, setJoinedDinner] = useState(false); //localStorage.getItem("dinnerJ")
  const [response, setResponse] = useState();

  const [dayImpact, setDayImpact] = useState(0);
  const [firstSave, setFirstSave] = useState(false); 
   var rowindex = parseInt(localStorage.getItem("rowindex"));
  useEffect(() => {
    setIsMounted(true);
   const user = localStorage.getItem("username");
   const date = localStorage.getItem("selectedDate");
   OOCSI.connect("wss://oocsi.id.tue.nl/ws");

   const data = {
     datum: date,
     personID: user,
   };

   OOCSI.send("food_ingredient_date", data);
   

   OOCSI.subscribe("State_participation", function (sts) {
     setResponse(sts.data.states);

     localStorage.setItem("rowindex", parseInt(sts.data.rowindex));

     //console.log(sts.data.states);

     const ingredienten = sts.data.ingredient;
     const ingCalc = JSON.parse(ingredienten);
     const allInfo = sts.data.infoin;
    // console.log(allInfo);
     const infoAllin = JSON.parse(allInfo) || [];
     localStorage.setItem("allinfo", infoAllin);
     setRecipeArray(infoAllin);
    

     //setFirstIngredient(ingCalc);
     const newArray = ingCalc.map((item) => ({
       value: item[0],
       label: item[0],
       weight: item[1],
     }));

         setRecipeName(sts.data.recipename);
         setIngredients(newArray);
         setDayImpact(sts.data.dayimpact);
    
   });

     const value = response == "Ja" ? true : false;
     localStorage.setItem("dinnerJ", value);
     setJoinedDinner(value);

      if (date) {
        setSelectedDate(date);
    //    const localStorageKey = `ingredients-${selectedDate}`;
    //    const savedIngredients =
    //      JSON.parse(localStorage.getItem(localStorageKey)) || [];
    //    if (Array.isArray(savedIngredients)) {
    //      setIngredients(savedIngredients);
    //      //console.log(ingredients);
        
        }

    //    const savedRecipeName = localStorage.getItem(`recipeName-${date}`);
    //    setRecipeName(savedRecipeName || "");

    //    const savedCounter = localStorage.getItem(`counter-${date}`);
    //    setCounter(parseInt(savedCounter) || 0);
    //  } 

   
   return () => {
     setIsMounted(false);
   };

 }, [response, isMounted]);



  const navigate = useNavigate();

  const onFrameButtonClick = useCallback(() => {
    if (isMounted) {
      navigate("/diary");
    }
  }, [navigate, isMounted]);

   const onFrameButtonClick7 = useCallback(() => {
     if (isMounted) {
       navigate("/overview");
     }
   }, [navigate, isMounted]);
  const onFrameButton1Click = useCallback(() => {
    if (isMounted) {
      navigate("/diary");
    }
  }, [navigate, isMounted]);

  const onFrameButton2Click = useCallback(() => {
    if (isMounted) {
      navigate("/recipes");
    }
  }, [navigate, isMounted]);

  const onFrameButton3Click = useCallback(() => {
    if (isMounted) {
      navigate("/chat");
    }
  }, [navigate, isMounted]);

  const onFrameButton4Click = useCallback(() => {
    if (isMounted) {
      navigate("/profiel");
    }
  }, [navigate, isMounted]);


  const onFrameContainer1Click = useCallback(() => {
    if (isMounted) {
      navigate("/Overview");
    }
  }, [navigate, isMounted]);

  const onVectorIconClick = useCallback(() => {
    if (isMounted) {
      navigate("/Overview");
    }
  }, [navigate, isMounted]);

  const onEditClick = useCallback(() => {
    if (isMounted) {
      setTimeout(() => {
        navigate("/editing");
      },1000)
      
    }
  }, [navigate, isMounted]);

  const selectedRecipeIngredients = recipeArray.map((ingredient) => ({
    categorie: ingredient[4],
    name: ingredient[0],
    quantity: ingredient[6],
    climateImpact: parseInt(ingredient[2]),
    calories: parseInt(ingredient[1]),
    schijf: ingredient[3],
  }));
 
  const recipeContent = selectedRecipeIngredients.map((ingredient) => (
       
      <section className="ingredient6" id={ingredient.id}>
        <p className="kcal6" id="Category">
          <p
            className={`kcal6 ${
              ingredient.categorie === "Overig"
                ? "Category1"
                : ingredient.categorie === "Vlees en Vis"
                ? "Category2"
                : ingredient.categorie === "Groente en Fruit"
                ? "Category3"
                : ingredient.categorie === "Zuivel"
                ? "Category4"
                : ingredient.categorie === "Granen"
                ? "Category5"
                : ""
            }`}
            id="Category"
          ></p>
        </p>
        <p className="kcal6" id="Ingredient">
          {ingredient.name}
        </p>
        <p className="kcal6" id="CalorieIngredient">
          {ingredient.quantity}
        </p>
        <p className="kcal6" id="ClimateImpactIngredient">
          {ingredient.climateImpact}
        </p>
        <p className="kcal6" id="WeightIngredient">
          {ingredient.calories}
        </p>
        <p className="kcal6" id="SchijfIngredient">
          {ingredient.schijf === "yes" ? "ja" : "nee"}
        </p>
      </section>
       
    ));

  return (
    <div className="detailed-day">
      <div className="top-bar2">
        <button className="back-container" onClick={onFrameButtonClick7}>
          <img className="back-icon1" alt="" src="/back1.svg" />
        </button>
        <div className="cibus-frame">
          <div className="cibus2">CIBUS</div>
        </div>
        <div className="vector-wrapper" onClick={onFrameContainer1Click}>
          <img
            className="vector-icon7"
            alt=""
            src="/vector6.svg"
            onClick={onVectorIconClick}
          />
        </div>
      </div>
      <div className="top-bar">
        <button className="back-wrapper" onClick={onFrameButtonClick}>
          <img className="back-icon" alt="" src="/back.svg" />
        </button>
        <div className="cibus-wrapper">
          <div className="cibus">CIBUS</div>
        </div>
        <img className="vector-icon" alt="" src="/vector.svg" />
      </div>
      <div className="date">
        <div className="cibus">{selectedDate} </div>
        <div className="text_below">
          <div className="text"> CO</div>
          <sub>2 &nbsp;</sub>
          <div className="text"> impact huishouden: &nbsp;</div>
          <div className="text-number"> {dayImpact} &nbsp;</div>
          <div className="text"> gram</div>
        </div>
        <div className="text_below">Feitje van de dag: {facts[rowindex]}</div>
      </div>
      <div className="Meegegeten-Ja">
        <button className="edit" onClick={onEditClick}>
          <img className="edit-icon1" alt="" src="/edit1.svg" />
        </button>
        <div className="text_below2">
          Meegegeten:{""} {response}
        </div>
      </div>
      <div className="recipeName">
        <div className="recipeName2">{recipeName}</div>
      </div>
      <div className="organize">
        <div className="ingredient-group">
          <section className="ingredient6">
            <p className="kcal6" id="Category">
              <b></b>
            </p>
            <p className="kcal6" id="Ingredient">
              <b>Ingrediënt</b>
            </p>
            <p className="kcal6" id="CalorieIngredient">
              <b>Hoeveelheid (g)</b>
            </p>
            <p className="kcal6" id="ClimateImpactIngredient">
              <b>
                Impact (g CO<sub>2</sub>)
              </b>
            </p>
            <p className="kcal6" id="WeightIngredient">
              <b>Calorieën</b>
            </p>
            <p className="kcal6" id="SchijfIngredient">
              <b>Schijf van 5</b>
            </p>
          </section>
          {recipeContent}
        </div>
      </div>

      {/* <Footer
        dimensions="/piechartoutlined2.svg"
        dimensionsCode="/vector10.svg"
        dimensionsCode2="/-icon-fast-food-outline.svg"
        dimensionsCode3="/-icon-chatbubble-ellipses-outline.svg"
        dimensionsCode4="/-icon-person.svg"
        propAlignSelf="unset"
        propColor="#9f9f9f"
        propColor1="#9d4571"
        propColor2="#9f9f9f"
        propColor3="#9f9f9f"
        onFrameButtonClick={onFrameButtonClick}
        onFrameButton1Click={onFrameButton1Click}
        onFrameButton2Click={onFrameButton2Click}
        onFrameButton3Click={onFrameButton3Click}
        onFrameButton4Click={onFrameButton4Click}
      /> */}
    </div>
  );
};

export default DetailedDay;
