import "./IngredientsFilter.css";
import { useCallback, useEffect, useState } from "react";
var OOCSI = require("oocsi");


const IngredientsFilter = () => {
    const [recipeArray, setRecipeArray] = useState([]);
    
    const [selectedDate, setSelectedDate] = useState("");

    const facts = JSON.parse(localStorage.getItem("myFacts"));
    // console.log("facts", JSON.parse(facts));

    const [counter, setCounter] = useState(0);
    const [ingredients, setIngredients] = useState([
        { value: "", weight: "" },
    ]);

    const [recipeName, setRecipeName] = useState("");

    const [listinfo, setListinfo] = useState([{ value: "", label: "" }]);
    const [joinedDinner, setJoinedDinner] = useState(false); //localStorage.getItem("dinnerJ")
    const [response, setResponse] = useState();

    const [dayImpact, setDayImpact] = useState(0);
    const [firstSave, setFirstSave] = useState(false);

    useEffect(() => {
        const storedRecipeArray = JSON.parse(localStorage.getItem("allinfo"));
      setRecipeArray(storedRecipeArray);
      console.log(typeof recipeArray);
    }, []);
    
useEffect(() => {
   const user = localStorage.getItem("username");
   const date = localStorage.getItem("selectedDate");
   OOCSI.connect("wss://oocsi.id.tue.nl/ws");

   const data = {
     datum: date,
     personID: user,
   };

   OOCSI.send("food_ingredient_date", data);

   OOCSI.subscribe("State_participation", function (sts) {
     setResponse(sts.data.states);

     localStorage.setItem("rowindex", parseInt(sts.data.rowindex));

     console.log(sts.data.states);

     const ingredienten = sts.data.ingredient;
     const allInfo = sts.data.infoin;
     const infoAllin = JSON.parse(allInfo);
    // console.log(infoAllin);
       setRecipeArray(infoAllin);

  
   });



 }, []);
   var rowindex = parseInt(localStorage.getItem("rowindex"));



  const selectedRecipeIngredients = recipeArray.map((ingredient) => ({
  categorie: ingredient[4],
  name: ingredient[0],
  quantity: ingredient[6],
  climateImpact: parseInt(ingredient[2]),
  calories: parseInt(ingredient[1]),
  schijf: ingredient[3],
}));


    const recipeContent = selectedRecipeIngredients.map((ingredient) => (
      <section className="ingredient6" id={ingredient.id}>
        <p
          className={`kcal6 ${
            ingredient.categorie === "Overig"
              ? "Category1"
              : ingredient.categorie === "Vlees en Vis"
              ? "Category2"
              : ingredient.categorie === "Groente en Fruit"
              ? "Category3"
              : ingredient.categorie === "Zuivel"
              ? "Category4"
              : ingredient.categorie === "Granen"
              ? "Category5"
              : ""
          }`}
          id="Category"
        ></p>
        <p className="kcal6" id="Ingredient">
          {ingredient.name}
        </p>
        <p className="kcal6" id="CalorieIngredient">
          {ingredient.quantity}
        </p>
        <p className="kcal6" id="ClimateImpactIngredient">
          {ingredient.climateImpact}
        </p>
        <p className="kcal6" id="WeightIngredient">
          {ingredient.calories}
        </p>
        <p className="kcal6" id="SchijfIngredient">
          {ingredient.schijf === "yes" ? "ja" : "nee"}
        </p>
      </section>
    ));

    return (
      <div className="organize">
        <div className="ingredient-group">
          <section className="ingredient6">
            <p className="kcal6" id="Category">
              <b></b>
            </p>
            <p className="kcal6" id="Ingredient">
              <b>Ingrediënt</b>
            </p>
            <p className="kcal6" id="CalorieIngredient">
              <b>Hoeveelheid (gram)</b>
            </p>
            <p className="kcal6" id="ClimateImpactIngredient">
              <b>
                Klimaatimpact (gram CO<sub>2</sub>)
              </b>
            </p>
            <p className="kcal6" id="WeightIngredient">
              <b>Calorieën (Cal)</b>
            </p>
            <p className="kcal6" id="SchijfIngredient">
              <b>Schijf van 5</b>
            </p>
          </section>
          {recipeContent}
        </div>
      </div>
    );



};

export default IngredientsFilter;