import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./GegevensVergeten.css";

const GegevensVergeten = ({ onClose }) => {
  const navigate = useNavigate();

  const onKruisButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="gegevens-vergeten">
      <div className="kruisje2">
        <button className="kruisbutton2" onClick={onKruisButtonClick}>
          <img className="vector-icon4" alt="" src="/vector3.svg" />
        </button>
      </div>
      <b className="gegevens-vergeten1">Gegevens vergeten</b>
      <div className="gebruik-de-persoonlijke-container">
        <p className="gebruik-de-persoonlijke">
          Gebruik de persoonlijke gebruikersnaam + wachtwoord die voor jou is
          gemaakt
        </p>
        <p className="gebruik-de-persoonlijke">
          Mocht je de gegevens toch niet meer kunnen vinden, dan kan je altijd
          contact opnemen met de onderzoeker:
        </p>
        <p className="gebruik-de-persoonlijke">Britt Stegers</p>
        <p className="gebruik-de-persoonlijke">+31621938925</p>
      </div>
    </div>
  );
};

export default GegevensVergeten;
