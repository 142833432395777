import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Overview from "./pages/Overview";
import Editing from "./pages/Editing";
import Login from "./pages/Login";
import FrameComponent from "./pages/FrameComponent";
import DetailedDay from "./pages/DetailedDay";
import Diary from "./pages/Diary";
import RecipesContent from "./pages/RecipesContent";
import AddingData from "./pages/AddingData";
import AddingRecipe from "./pages/AddingRecipe";
import Recipes from "./pages/Recipes";
import Profiel from "./pages/Profiel";
import Chat from "./pages/Chat";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/editing":
        title = "";
        metaDescription = "";
        break;
      case "/login":
        title = "";
        metaDescription = "";
        break;
      case "/frame-2250":
        title = "";
        metaDescription = "";
        break;
      case "/detailed-day":
        title = "";
        metaDescription = "";
        break;
      case "/diary":
        title = "";
        metaDescription = "";
        break;
      case "/recipes-content":
        title = "";
        metaDescription = "";
        break;
      case "/adding-data":
        title = "";
        metaDescription = "";
        break;
      case "/adding-recipe":
        title = "";
        metaDescription = "";
        break;
      case "/recipes":
        title = "";
        metaDescription = "";
        break;
      case "/profiel":
        title = "";
        metaDescription = "";
        break;
      case "/chat":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/Overview" element={<Overview />} />
      <Route path="/editing" element={<Editing />} />
      <Route path="/" element={<Login />} />
      <Route path="/frame-2250" element={<FrameComponent />} />
      <Route path="/detailed-day" element={<DetailedDay />} />
      <Route path="/diary" element={<Diary />} />
      <Route path="/recipes-content" element={<RecipesContent />} />
      <Route path="/adding-data" element={<AddingData />} />
      <Route path="/adding-recipe" element={<AddingRecipe />} />
      <Route path="/recipes" element={<Recipes />} />
      <Route path="/profiel" element={<Profiel />} />
      <Route path="/chat" element={<Chat />} />
    </Routes>
  );
}
export default App;
