import IngredientContainer from "./IngredientContainer";
import "./ContainerForm.css";

const ContainerForm = () => {
  return (
    <div className="main2">
      <div className="content">
        <div className="aantal-personen1">
          <section className="buttonplusmin">
            <button className="frame-button">
              <b className="b3">-</b>
            </button>
            <button className="frame-button">
              <b className="b3">+</b>
            </button>
          </section>
          <div className="persons">
            <b className="b5">00</b>
            <b className="personen1">personen</b>
          </div>
        </div>
        <IngredientContainer productId="/info2.svg" />
        <button className="opslaan3">
          <div className="button12">
            <div className="button13">Opslaan</div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ContainerForm;
