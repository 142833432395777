import { useState, useEffect } from "react";
import "./LoginContent.css";

const LoginContent = ({ onUsernameChange, onPasswordChange }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    onUsernameChange(event.target.value);
  };
  

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    onPasswordChange(event.target.value);
  };

  return (
    <div className="logincontent">
      <div className="loginheader">
        <h1 className="welkom-bij-cibus">Welkom bij Cibus</h1>
        <p className="gebruik-de-persoonlijke1">
          Gebruik de persoonlijke gebruikersnaam en het wachtwoord die voor jou
          is aangemaakt
        </p>
      </div>
      <div className="registerpart">
        <input
          className="gebruikersnaam2"
          type="username"
          placeholder="Gebruikersnaam"
          value={username}
          onChange={handleUsernameChange}
          required
        />
        <input
          className="gebruikersnaam2"
          type="password"
          placeholder="Wachtwoord"
          value={password}
          onChange={handlePasswordChange}
          required
        />
      </div>
    </div>
  );
};

export default LoginContent;
