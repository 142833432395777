import ChatContainer from "../components/ChatContainer";
import Footer from "../components/Footer";
import "./Chat.css";
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const Chat = () => {
  const navigate = useNavigate();

  const onFrameButton1Click = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const onFrameButton2Click = useCallback(() => {
    navigate("/diary");
  }, [navigate]);

  const onFrameButton3Click = useCallback(() => {
    navigate("/recipes");
  }, [navigate]);

  const onFrameButton4Click = useCallback(() => {
    navigate("/chat");
  }, [navigate]);

  const onFrameButton5Click = useCallback(() => {
    navigate("/profiel");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const onVectorIconClick = useCallback(() => {
    navigate("/Overview");
  }, [navigate]);

  const [messages, setMessages] = useState([]);

  const onSendMessage = useCallback((message) => {
    setMessages([...messages, message]);
  }, [messages]);
  

  return (
    <div className="chat">
      <div className="top-bar9">
        <button className="vector-container" onClick={onFrameButtonClick}>
          <img
            className="vector-icon17"
            alt=""
            src="/vector8.svg"
            onClick={onVectorIconClick}
          />
        </button>
        <div className="cibus-wrapper2">
          <div className="cibus9">CIBUS</div>
        </div>
        <img className="vector-icon18" alt="" src="/vector.svg" />
      </div>
      <section className="infotext1">
        <p className="je-kan-aan">{`Als je deze chatbot gebruikt, is het belangrijk om te weten waarvoor je hem kunt gebruiken en hoe je dat op een goede manier doet. Deze chatbot helpt je bij het vinden van duurzame en gezonde voedselkeuzes en geeft informatie over ingrediënten en recepten. Gebruik de chatbot dus alleen om kennis te krijgen over duurzame en gezonde voeding. Als je de chatbot gebruikt, geef dan duidelijk aan over welk ingrediënt of recept je meer wilt weten. Belangrijk om te weten is dat je geen persoonlijke of gevoelige informatie moet delen via de chatbot. Zo kunnen we er samen voor zorgen dat het gebruik van de chatbot veilig blijft. Om meer te lezen over wie jouw vragen terug kan zien, kijk dan bij het profiel onder disclaimer.`}</p>
      </section>
      <div className="allcontent">
        <ChatContainer messages={messages} onSendMessage={onSendMessage} />
      </div>

      <Footer
        dimensions="/piechartoutlined4.svg"
        dimensionsCode="/vector13.svg"
        dimensionsCode2="/-icon-fast-food-outline4.svg"
        dimensionsCode3="/-icon-chatbubble-ellipses-outline4.svg"
        dimensionsCode4="/-icon-person3.svg"
        propAlignSelf="stretch"
        propColor="#9f9f9f"
        propColor1="#9f9f9f"
        propColor2="#9f9f9f"
        propColor3="#F66047"
        onFrameButtonClick={onFrameButton1Click}
        onFrameButton1Click={onFrameButton2Click}
        onFrameButton2Click={onFrameButton3Click}
        onFrameButton3Click={onFrameButton4Click}
        onFrameButton4Click={onFrameButton5Click}
      />
    </div>
  );
};

export default Chat;
