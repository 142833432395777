import "./IngredientContainer.css";

const IngredientContainer = ({ productId }) => {
  return (
    <div className="ingredientlist">
      <div className="ingredient9">
        <p className="ingredinten1" id="StandardText" Ingredient>
          Ingrediënten
        </p>
        <button className="infobutton">
          <img className="info-icon2" alt="" src={productId} />
        </button>
      </div>
      <section className="ingredientlist-inner" id="ingredientData">
        <div className="frame-parent13">
          <input
            className="frame-child2"
            type="text"
            placeholder="Typ en kies een ingrediënt"
          />
          <div className="frame-wrapper3">
            <div className="frame-parent14">
              <input
                className="frame-child3"
                type="text"
                placeholder="gewicht"
              />
              <p className="gram2">gram</p>
            </div>
          </div>
        </div>
      </section>
      <section className="ingredientlist-inner" id="ingredientData">
        <div className="frame-parent13">
          <input
            className="frame-child2"
            type="text"
            placeholder="Typ en kies een ingrediënt"
          />
          <div className="frame-wrapper3">
            <div className="frame-parent14">
              <input
                className="frame-child3"
                type="text"
                placeholder="gewicht"
              />
              <p className="gram2">gram</p>
            </div>
          </div>
        </div>
      </section>
      <button className="button-container">
        <div className="button10">
          <b className="button11">extra ingrediënt toevoegen</b>
        </div>
      </button>
    </div>
  );
};

export default IngredientContainer;
