import { useState, useCallback } from "react";
import LoginContent from "../components/LoginContent";
import { useNavigate } from "react-router-dom";
import GegevensVergeten from "../components/GegevensVergeten";
import PortalPopup from "../components/PortalPopup";
import Privacy from "../components/Privacy";
import "./Login.css";
var OOCSI = require('oocsi')

const Login = () => {
  const navigate = useNavigate();
  const [isGegevensVergetenPopupOpen, setGegevensVergetenPopupOpen] =
    useState(false);
  const [isPrivacyPopupOpen, setPrivacyPopupOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onButtonClick = useCallback(async () => {
    try {
      // Connect to OOCSI
      OOCSI.connect("wss://oocsi.id.tue.nl/ws");
      var data = {
      "username": username,
      "password": password
      };
      OOCSI.send("User_auth_Food", data);
  
      OOCSI.subscribe("Authenticate", function(dat) {
        const response= dat.data.success;
        if (response === "Succeed") {
        // Save user credentials to local storage
          localStorage.setItem('username', username);
          localStorage.setItem('password', password);

        // Redirect to home page
        navigate('/Overview');
      } else {
        // Show error message if the authentication failed
        alert("Invalid username or password");
      }
    });
  } catch (error) {
    console.error(error);
    alert(error.message);
  }
}, [navigate, username, password]);


  const openGegevensVergetenPopup = useCallback(() => {
    setGegevensVergetenPopupOpen(true);
  }, []);

  const closeGegevensVergetenPopup = useCallback(() => {
    setGegevensVergetenPopupOpen(false);
  }, []);

  const openPrivacyPopup = useCallback(() => {
    setPrivacyPopupOpen(true);
  }, []);

  const closePrivacyPopup = useCallback(() => {
    setPrivacyPopupOpen(false);
  }, []);

  const handleUsernameChange = useCallback((value) => {
    setUsername(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setPassword(value);
  }, []);

  

  return (
    <>
      <div className="login">
        <div className="loginframe">
          <LoginContent
            onUsernameChange={handleUsernameChange}
            onPasswordChange={handlePasswordChange}
            
          />
          <div className="button-parent">
            <button className="button6" onClick={onButtonClick}>
              <div className="button7">Login</div>
            </button>
            <div className="gegevens-vergeten-parent">
              <button
                className="gegevens-vergeten2"
                onClick={openGegevensVergetenPopup}
              >
                Gegevens vergeten?
              </button>
              <button className="gegevens-vergeten2" onClick={openPrivacyPopup}>
                Privacy
              </button>
            </div>
          </div>
        </div>
      </div>
      {isGegevensVergetenPopupOpen && (
        <PortalPopup
          overlayColor="rgba(255, 255, 255, 0.5)"
          placement="Centered"
          onOutsideClick={closeGegevensVergetenPopup}
        >
          <GegevensVergeten onClose={closeGegevensVergetenPopup} />
        </PortalPopup>
      )}
      {isPrivacyPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePrivacyPopup}
        >
          <Privacy onClose={closePrivacyPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default Login;
