import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContainerForm from "../components/ContainerForm";
import "./AddingData.css";

const AddingData = () => {
  const navigate = useNavigate();

  const onBackClick = useCallback(() => {
    navigate("/diary");
  }, [navigate]);

  return (
    <div className="adding-data">
      <div className="top-bar5">
        <button className="back" onClick={onBackClick}>
          <img className="back-icon3" alt="" src="/back2.svg" />
        </button>
        <div className="name1">
          <div className="cibus5">CIBUS</div>
        </div>
        <img className="vector-icon11" alt="" src="/vector.svg" />
      </div>
      <div className="date1">
        <div className="datum9">Datum</div>
      </div>
      <ContainerForm />
    </div>
  );
};

export default AddingData;
